import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useCompanyProfileSetup = (steps) => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyEmailLoading, setIsVerifyEmailLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [submissionState, setSubmissionState] = useState("idle");
  const [ipAddress, setIpAddress] = useState("");

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      socialMedia: [],
    },
  });

  const watchPassword = watch("password");
  const watchEmail = watch("email");
  const watchUsername = watch("username");

  useEffect(() => {
    const fetchIPData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        setIpAddress(response.data);
      } catch (error) {
        console.error("Error fetching IP data: ", error);
      }
    };

    fetchIPData();
  }, []);

  const sendOtp = async () => {
    setIsVerifyEmailLoading(true);
    try {
      const data = JSON.stringify({ email: watchEmail });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseURL}/api/verify/verify-company-email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      if (response.data.success) {
        setIsOtpPopupOpen(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setShowToast(true);
      }
    } finally {
      setIsVerifyEmailLoading(false);
    }
  };

  const handleVerifyOtp = async (email, otp) => {
    try {
      const response = await fetch(`${baseURL}/api/verify/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });

      if (response.status === 200) {
        setEmailVerified(true);
        setIsOtpPopupOpen(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      return false;
    }
  };

  const onSubmit = async (data) => {
    const updatedFormData = { ...formData, ...data };
    setFormData(updatedFormData);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      try {
        setIsLoading(true);
        setSubmissionState("submitting");
        setIsPopupOpen(true);

        const formDataToSend = new FormData();
        if (updatedFormData.bannerImage) {
          formDataToSend.append("banner_image", updatedFormData.bannerImage);
        }
        if (updatedFormData.logo) {
          formDataToSend.append("company_logo_image", updatedFormData.logo);
        }
        if (updatedFormData.galleryImages?.length > 0) {
          updatedFormData.galleryImages.forEach((image) => {
            formDataToSend.append("gallery_images", image);
          });
        }

        const socialMedia = {
          instagramUrl: updatedFormData.socialMedia.instagramUrl,
          linkedinUrl: updatedFormData.socialMedia.linkedinUrl,
          facebookUrl: updatedFormData.socialMedia.facebookUrl,
          youtubeUrl: updatedFormData.socialMedia.youtubeUrl,
        };

        const dataToSend = {
          username: updatedFormData.username,
          email: updatedFormData.email,
          password: updatedFormData.password,
          role: "COMPANY",
          login_as: "COMPANY",
          company_name: updatedFormData.username,
          primary_company_type: updatedFormData.primaryType,
          secondary_company_type: updatedFormData.secondaryType,
          company_phone_number: updatedFormData.phone,
          company_email: updatedFormData.companyEmail,
          company_website: updatedFormData.companyWebsite,
          country: updatedFormData.country,
          state: updatedFormData.state,
          city: updatedFormData.city,
          company_zipcode: updatedFormData.zip,
          company_description: updatedFormData.description,
          business_type: updatedFormData.businessType,
          number_of_employees: updatedFormData.numberOfEmployees,
          annual_revenue: updatedFormData.revenue,
          year_of_establishment: updatedFormData.establishedYear,
          company_location: updatedFormData.address,
          company_social_media: socialMedia,
          company_video_links: updatedFormData.videoEmbLink,
          company_ip: ipAddress,
          user_approved: 0,
        };

        formDataToSend.append("data", JSON.stringify(dataToSend));

        const response = await axios.post(
          `${baseURL}/api/users/signup`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.data?.status === true) {
          setSubmissionState("success");
          setIsPopupOpen(true);

          setTimeout(() => {
            setIsPopupOpen(false);
            navigate("/");
          }, 2000);
        }
      } catch (error) {
        console.error("API Error:", error);
        setSubmissionState("error");
        setIsPopupOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return {
    currentStep,
    isPopupOpen,
    isOtpPopupOpen,
    isLoading,
    emailVerified,
    errorMessage,
    isVerifyEmailLoading,
    showToast,
    submissionState,
    setSubmissionState,
    control,
    errors,
    watch,
    watchPassword,
    watchEmail,
    watchUsername,
    setIsPopupOpen,
    setIsOtpPopupOpen,
    setShowToast,
    handleSubmit,
    sendOtp,
    handleVerifyOtp,
    onSubmit,
    prevStep,
  };
};
