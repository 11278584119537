import React from "react";

export const AnimatedSpinner = ({ size, className }) => {
  return (
    <div
      className={`buttonloader ${className}`}
      style={{
        width: size.width ? `${size.width}px` : "24px",
        height: size.height ? `${size.height}px` : "24px",
      }}
    />
  );
};
