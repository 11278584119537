import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/common/Scroll To Top/scrolltotop";
import { Cookie } from "./components/common/Cookie";
import PrivateRoutes from "./PrivateRoutes";
import ReactGA from "react-ga4";

function App() {
  const TRACKING_ID = "G-NBLQS6HTG4";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "/goatarch-home", title: "Home Page" });

  return (
    <div className="App">
      <Helmet>
        <title>GoatArch Design and Products</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) =>
            route.private ? (
              <Route
                key={index}
                path={route.path}
                element={<PrivateRoutes component={route.component} />}
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                element={React.createElement(route.component)}
              />
            )
          )}
        </Routes>
        <Cookie />
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;