import React, { useState, useEffect } from "react";
import {
  EyeIcon,
  EyeSlashIcon,
  ChevronDownIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import { useController } from "react-hook-form";
import { FileUpload } from "../File Upload/fileupload";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const passwordStrengthIndicator = (password) => {
  const strengthChecks = {
    length: password.length >= 8,
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasNumber: /\d/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  const strength = Object.values(strengthChecks).filter(Boolean).length;

  return {
    score: strength,
    checks: strengthChecks,
  };
};

export const Input = ({
  name,
  control,
  rules,
  label,
  type = "text",
  options = [],
  placeholder = "",
  className = "",
  rows = 3,
  allowSearch = false,
  showPasswordStrength = false,
  international = false,
  inlineLabel,
  disabled = false,
  // Date input props
  allowPrevious = true,
  allowFuture = true,
  // Time input props
  use24Hour = false,
  // File input props
  acceptedFileTypes = "all",
  multiple = false,
  maxFiles = Infinity,
  maxSizeInMB = 5,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [timeFormat, setTimeFormat] = useState("AM");

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleSelect = () => setIsSelectOpen(!isSelectOpen);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (showPasswordStrength && type === "password" && field.value) {
      const strength = passwordStrengthIndicator(field.value);
      setPasswordStrength(strength);
    } else {
      setPasswordStrength(null);
    }
  }, [field.value, showPasswordStrength, type]);

  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Calculate min and max dates based on allowPrevious and allowFuture
  const getDateConstraints = () => {
    const today = getCurrentDate();
    return {
      min: allowPrevious ? undefined : today,
      max: allowFuture ? undefined : today,
    };
  };

  const formatDisplayTime = (time24) => {
    if (!time24 || typeof time24 !== "string") return "";

    const [hours, minutes] = time24.split(":");
    const hour = parseInt(hours, 10);

    if (!use24Hour) {
      if (hour === 0) {
        return `12:${minutes}`;
      } else if (hour > 12) {
        return `${(hour - 12).toString().padStart(2, "0")}:${minutes}`;
      } else {
        return `${
          hour === 12 ? 12 : hour.toString().padStart(2, "0")
        }:${minutes}`;
      }
    }
    return time24;
  };

  // Handle initial time format
  const updateTimeFormat = (time24) => {
    if (!time24 || typeof time24 !== "string") return;

    const parts = time24.split(":");
    if (parts.length !== 2) return;

    const hour = parseInt(parts[0], 10);
    if (isNaN(hour)) return;

    if (hour >= 12) {
      setTimeFormat("PM");
    } else {
      setTimeFormat("AM");
    }
  };

  // Handle time input change
  const handleTimeChange = (e) => {
    const newTime = e.target.value;

    if (!newTime) {
      field.onChange("");
      return;
    }

    const [hours, minutes] = newTime.split(":");
    let hour = parseInt(hours, 10);

    if (isNaN(hour)) return;

    // Convert to 24-hour format if necessary
    if (!use24Hour) {
      if (timeFormat === "PM" && hour !== 12) {
        hour += 12;
      } else if (timeFormat === "AM" && hour === 12) {
        hour = 0;
      }
    }

    const time24 = `${hour.toString().padStart(2, "0")}:${minutes}`;
    field.onChange(time24);

    if (props.onChange) {
      props.onChange({ target: { value: time24 } });
    }
  };

  // Handle AM/PM change
  const handleFormatChange = (e) => {
    const newFormat = e.target.value;
    setTimeFormat(newFormat);

    if (field.value && typeof field.value === "string") {
      const parts = field.value.split(":");
      if (parts.length !== 2) return;

      const [hours, minutes] = parts;
      let hour = parseInt(hours, 10);

      if (isNaN(hour)) return;

      if (newFormat === "PM" && hour < 12) {
        hour += 12;
      } else if (newFormat === "AM" && hour >= 12) {
        hour = hour === 12 ? 0 : hour - 12;
      }

      const newTime = `${hour.toString().padStart(2, "0")}:${minutes}`;
      field.onChange(newTime);

      if (props.onChange) {
        props.onChange({ target: { value: newTime } });
      }
    }
  };

  // Update time format when value changes
  React.useEffect(() => {
    if (!use24Hour && field.value) {
      updateTimeFormat(field.value);
    }
  }, [field.value, use24Hour]);

  const renderPasswordStrengthIndicator = () => {
    if (!passwordStrength || !isFocused) return null;

    const { score, checks } = passwordStrength;
    const strengthColors = [
      "bg-red-500",
      "bg-orange-500",
      "bg-yellow-500",
      "bg-green-500",
      "bg-green-600",
    ];

    return (
      <div className="mt-2">
        <div className="flex mb-1">
          {[0, 1, 2, 3, 4].map((index) => (
            <div
              key={index}
              className={`h-2 w-1/5 ${
                index < score ? strengthColors[score - 1] : "bg-gray-300"
              } mr-1`}
            ></div>
          ))}
        </div>
        <ul className="text-sm">
          <li className={checks.length ? "text-green-600" : "text-red-500"}>
            {checks.length ? "✓" : "✗"} At least 8 characters
          </li>
          <li
            className={checks.hasUpperCase ? "text-green-600" : "text-red-500"}
          >
            {checks.hasUpperCase ? "✓" : "✗"} Contains uppercase letter
          </li>
          <li
            className={checks.hasLowerCase ? "text-green-600" : "text-red-500"}
          >
            {checks.hasLowerCase ? "✓" : "✗"} Contains lowercase letter
          </li>
          <li className={checks.hasNumber ? "text-green-600" : "text-red-500"}>
            {checks.hasNumber ? "✓" : "✗"} Contains number
          </li>
          <li
            className={
              checks.hasSpecialChar ? "text-green-600" : "text-red-500"
            }
          >
            {checks.hasSpecialChar ? "✓" : "✗"} Contains special character
          </li>
        </ul>
      </div>
    );
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const renderInput = () => {
    switch (type) {
      case "date":
        const dateConstraints = getDateConstraints();
        return (
          <input
            {...field}
            type="date"
            min={dateConstraints.min}
            max={dateConstraints.max}
            disabled={disabled}
            placeholder={placeholder}
            className={`outline-none flex-1 pr-3 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] pr-[12px] focus:border-theme-color focus:border-[2px] ${className}`}
            {...props}
          />
        );
      case "time":
        return (
          <div className="flex items-center gap-2">
            <input
              {...field}
              type="time"
              disabled={disabled}
              placeholder={placeholder}
              className={`outline-none flex-1 pr-3 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] pr-[12px] focus:border-theme-color focus:border-[2px] ${className}`}
              onChange={handleTimeChange}
              value={formatDisplayTime(field.value)}
              {...props}
            />
            {!use24Hour && (
              <select
                value={timeFormat}
                onChange={handleFormatChange}
                className="outline-none border border-theme-color rounded-[4px] py-[14px] px-[12px] focus:border-theme-color focus:border-[2px]"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            )}
          </div>
        );

      case "select":
        return (
          <div className="relative">
            <div
              onClick={toggleSelect}
              className={`outline-none flex items-center justify-between border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] pr-[12px] focus:border-theme-color focus:border-[2px] cursor-pointer ${className}`}
            >
              <span>
                {options.find((opt) => opt.value === field.value)?.label ||
                  "Select an option"}
              </span>
              <ChevronDownIcon
                className={`h-5 w-5 text-theme-color transition-transform ${
                  isSelectOpen ? "transform rotate-180" : ""
                }`}
              />
            </div>
            {isSelectOpen && (
              <div className="absolute z-10 w-full mt-1 bg-theme-light-bg border border-theme-color rounded-md shadow-lg max-h-60 overflow-auto">
                {allowSearch && (
                  <div className="p-2 sticky top-0 bg-theme-light-bg">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-theme-color"
                      />
                      <MagnifyingGlassCircleIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                )}
                <ul className="text-left">
                  {filteredOptions.map((option) => (
                    <li
                      key={option.value}
                      className={`px-4 py-2 hover:bg-theme-color cursor-pointer ${
                        field.value === option.value ? "bg-theme-color" : ""
                      }`}
                      onClick={() => {
                        field.onChange(option.value);
                        toggleSelect();
                        if (props.onChange) {
                          props.onChange({ target: { value: option.value } });
                        }
                      }}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      case "tel":
        return international ? (
          <PhoneInput
            {...field}
            placeholder={placeholder}
            international
            countryCallingCodeEditable={false}
            className={`flex-1 pr-3 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] focus:border-theme-color focus:border-[0px] ${className}`}
            {...props}
          />
        ) : (
          <input
            {...field}
            type="tel"
            placeholder={placeholder}
            className={`flex-1 pr-3 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] focus:border-theme-color focus:border-[0px] ${className}`}
            {...props}
          />
        );
      case "password":
        return (
          <div className="relative">
            <div className="relative">
              <input
                {...field}
                type={showPassword ? "text" : "password"}
                placeholder={placeholder}
                className={`outline-none flex-1 pr-10 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] pr-[12px] focus:border-theme-color focus:border-[2px] ${className}`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                {...props}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {showPasswordStrength && renderPasswordStrengthIndicator()}
          </div>
        );
      case "file":
        return (
          <FileUpload
            onFileChange={(files) => {
              field.onChange(files);
              if (props.onChange) {
                props.onChange({ target: { value: files } });
              }
            }}
            acceptedTypes={acceptedFileTypes}
            multiple={multiple}
            maxFiles={maxFiles}
            maxSizeInMB={maxSizeInMB}
            value={field.value}
            error={error}
            disabled={disabled}
            {...props}
          />
        );
      case "textarea":
        return (
          <ReactQuill
            theme="snow"
            value={field.value || ""}
            onChange={(content) => {
              field.onChange(content);
              if (props.onChange) {
                props.onChange({ target: { value: content } });
              }
            }}
            modules={quillModules}
            formats={quillFormats}
            placeholder={placeholder}
            style={{
              height: `${rows * 24}px`,
              marginBottom: "20px",
            }}
            {...props}
          />
        );
      case "checkbox":
        return (
          <div>
            {label && (
              <label
                htmlFor={name}
                className="block mb-2 text-sm font-medium text-left"
              >
                {label}
              </label>
            )}
            <div className="flex items-center">
              <input
                {...field}
                type="checkbox"
                id={name}
                className="h-4 w-4 text-theme-color border-theme-color rounded focus:ring-theme-color"
                {...props}
              />
              {inlineLabel && (
                <label
                  htmlFor={name}
                  className="ml-2 block text-sm text-gray-900"
                  dangerouslySetInnerHTML={{ __html: inlineLabel }}
                />
              )}
            </div>
          </div>
        );
      default:
        return (
          <input
            {...field}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            className={`outline-none flex-1 pr-3 border caret-theme-color w-full placeholder:text-gray-400 placeholder:text-text placeholder:font-chivo border-theme-color rounded-[4px] py-[14px] pl-[16px] pr-[12px] focus:border-theme-color focus:border-[2px] ${className}`}
            {...props}
          />
        );
    }
  };

  return (
    <div className="mb-6">
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-left"
        >
          {label}
        </label>
      )}
      {renderInput()}
      {error && <span className="text-red-500 text-sm">{error.message}</span>}
    </div>
  );
};
