import React, { useEffect } from "react";
import { Input } from "../../../../../components/shared";
import { useController, useFieldArray } from "react-hook-form";
import { PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";

const categories = [
  {
    name: "Finishes",
    subcategories: [
      "False Ceilings",
      "Walls and Coverings",
      "Doors",
      "Paints & Coatings",
      "Roofing",
      "Windows",
      "Fittings",
      "Surfaces",
      "Blinds Curtains Awnings",
    ],
  },
  {
    name: "Acoustic",
    subcategories: [
      "Wall cladding",
      "Insulation",
      "Ceilings",
      "Flooring",
      "Fabrics",
      "Partitions",
    ],
  },
  {
    name: "Construction Materials",
    subcategories: [
      "Elevators Escalators Lifts",
      "Horizontal Transport",
      "Gates and Fences",
      "Hardware",
      "Materials",
      "Chemicals",
      "Stairs and Rails",
      "Insulation",
      "Roofing",
      "Paving and Decking",
      "Waterproofing",
      "Façade and Glazing",
      "Structural Frames",
      "Prefabricated Structures",
    ],
  },
  {
    name: "Features and Elements",
    subcategories: [
      "Fountains",
      "Fireplace Stoves BBQ",
      "Pods",
      "Sculptures and Objects",
      "Art and Prints",
      "Stabile",
      "Display Elements",
    ],
  },
  {
    name: "Furniture",
    subcategories: [
      "Beds",
      "Fittings",
      "Home Office",
      "Office Furniture",
      "Seating and Chairs",
      "Speciality Furniture",
      "Storage",
      "Tables",
    ],
  },
  {
    name: "Flooring",
    subcategories: [
      "Carpets and Rugs",
      "Concrete Floors",
      "Wooden Flooring",
      "Raised Floor",
      "Accessories and Covers",
      "Glass Flooring",
      "Hardwood Flooring",
      "Porcelain Floor",
      "Mosaic",
      "Luxury Vinyl Tiles",
      "Glass Flooring",
      "Laminate",
      "Rubber Flooring",
    ],
  },
  {
    name: "Bath and Sanitary",
    subcategories: [
      "Bathroom Furniture",
      "Faucets",
      "Shower",
      "Sinks",
      "Spa and Wellness",
      "Toilets Urinal",
      "Plumbing",
      "Bath Kitchens",
      "Bathroom Accessories",
    ],
  },
  {
    name: "Lighting and Fittings",
    subcategories: [
      "Urban Lighting",
      "Light Fittings",
      "Bulbs and Spots",
      "Wall Lights",
      "Floor Lights",
      "Speciality Lights",
      "Track Lighting",
      "Hidden Concealed Lighting",
    ],
  },
  {
    name: "AV and IT",
    subcategories: [
      "Appliances",
      "Multimedia Units",
      "Audio Video Devices",
      "Mobile Devices",
    ],
  },
  {
    name: "Equipment",
    subcategories: [
      "Electrical",
      "Food Service Equipment",
      "Kitchen Equipment",
      "Fitness",
      "Tech Gym",
    ],
  },
  {
    name: "Materials",
    subcategories: [
      "Glass",
      "Ceramics",
      "Concrete",
      "Bricks & Masonry",
      "Metal",
      "Textile",
      "Wood",
      "Stone",
      "Plastic and Fibers",
      "Stucco and Mortars",
    ],
  },
  {
    name: "Signage and Wayfinding",
    subcategories: [
      "Signage System",
      "Pictographs",
      "Plates",
      "Signage Digital Systems",
      "Wayfinding",
    ],
  },
  {
    name: "Landscape and Outdoor",
    subcategories: [
      "Planters",
      "Outdoor Seating",
      "Trash Cans and Bins",
      "Outdoor Furniture",
      "Fences",
      "Paving",
      "Shades",
    ],
  },
  {
    name: "Manufacturing",
    subcategories: [
      "Plastic Products",
      "Household Items",
      "Bags",
      "Trash Cans and Bins",
      "Packaging Materials",
      "Industrial Equipment",
      "Tools and Hardware",
      "Metal Fabrication",
      "Woodwork Manufacturing",
      "Textile Manufacturing",
      "Glass Manufacturing",
      "Ceramics Manufacturing",
      "Paper Products",
    ],
  },
];

export const CreateProductStepOne = ({ control, errors }) => {
  const { field: categoryField } = useController({
    name: "category",
    control,
  });

  const { field: subCategoryField } = useController({
    name: "subCategory",
    control,
  });

  const {
    fields: characteristicsFields,
    append: appendCharacteristic,
    remove: removeCharacteristic,
  } = useFieldArray({
    control,
    name: "characteristics",
  });

  const {
    fields: sizesFields,
    append: appendSize,
    remove: removeSize,
  } = useFieldArray({
    control,
    name: "sizes",
  });

  useEffect(() => {
    if (characteristicsFields.length === 0) {
      appendCharacteristic({ value: "" });
      appendCharacteristic({ value: "" });
    }
    if (sizesFields.length === 0) {
      appendSize({ value: "" });
      appendSize({ value: "" });
    }
  }, []);

  const handleCategoryChange = (value) => {
    const category = categories.find((cat) => cat.name === value);
    if (category) {
      categoryField.onChange(value);
      subCategoryField.onChange("");
    } else {
      categoryField.onChange("");
      subCategoryField.onChange("");
    }
  };
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white lg:p-6 lg:shadow rounded">
          <h3 className="text-xl font-semibold text-gray-900 mb-6">
            Product Details
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Input
              name="productName"
              control={control}
              rules={{ required: "Products name is required" }}
              label="Products Name"
              placeholder="Enter products name"
            />
            <Input
              name="collection"
              control={control}
              rules={{ required: "collection name is required" }}
              label="Collection Name"
              placeholder="Enter collection name"
            />
            <Input
              name="category"
              rules={{ required: "Category is required" }}
              control={control}
              label="Category"
              type="select"
              options={[
                { value: "", label: "Select Category" },
                ...categories.map((category) => ({
                  value: category.name,
                  label: category.name,
                })),
              ]}
              onChange={(e) => handleCategoryChange(e.target.value)}
            />
            <Input
              name="subCategory"
              rules={{ required: "Sub Category is required" }}
              control={control}
              label="Sub Category"
              type="select"
              options={[
                { value: "", label: "Select Sub Category" },
                ...(
                  categories.find((cat) => cat.name === categoryField.value)
                    ?.subcategories || []
                ).map((subCategory) => ({
                  value: subCategory,
                  label: subCategory,
                })),
              ]}
              disabled={!categoryField.value}
            />
            <div className="col-span-1 lg:col-span-2">
              <Input
                name="description"
                control={control}
                rules={{ required: "Description is required" }}
                label="Description"
                type="textarea"
                placeholder="Enter project description"
                rows={4}
                className="w-full"
              />
            </div>
            <div className="col-span-1 lg:col-span-2">
              <Input
                name="applicationOfUses"
                control={control}
                rules={{ required: "Application of uses required" }}
                label="Application of uses"
                type="textarea"
                placeholder="Enter Application of uses"
                rows={4}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 space-y-6 gap-6">
          <div className="bg-white lg:p-6 lg:shadow rounded">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">
              Characteristics
            </h3>
            {characteristicsFields.map((field, index) => (
              <div key={field.id} className="flex items-center space-x-2 mb-2">
                <div className="flex-grow">
                  <Input
                    name={`characteristics.${index}.value`}
                    control={control}
                    rules={{ required: "Characteristic is required" }}
                    placeholder="Enter characteristic"
                    className="w-full"
                  />
                </div>
                {characteristicsFields.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeCharacteristic(index)}
                    className="text-red-500 hover:text-red-700 flex-shrink-0"
                  >
                    <XCircleIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={() => appendCharacteristic({ value: "" })}
              className="mt-2 flex items-center text-theme-color hover:text-theme-color-dark"
            >
              <PlusIcon className="h-5 w-5 mr-1" />
              Add Characteristic
            </button>
          </div>
          <div className="bg-white lg:p-6 lg:shadow rounded">
            <h3 className="text-xl font-semibold text-gray-900 mb-6">Sizes</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {sizesFields.map((field, index) => (
                <div
                  key={field.id}
                  className="flex items-center space-x-2 mb-2 w-full"
                >
                  <div className="flex-grow">
                    <Input
                      name={`sizes.${index}.value`}
                      control={control}
                      rules={{ required: "Size is required" }}
                      placeholder="Enter size"
                      className="w-full"
                    />
                  </div>
                  {sizesFields.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeSize(index)}
                      className="text-red-500 hover:text-red-700 flex-shrink-0"
                    >
                      <XCircleIcon className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}
            </div>
            <button
              type="button"
              onClick={() => appendSize({ value: "" })}
              className="mt-2 flex items-center text-theme-color hover:text-theme-color-dark"
            >
              <PlusIcon className="h-5 w-5 mr-1" />
              Add Size
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
