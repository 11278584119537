import { Fragment } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { filters } from "../../data";

export const FilterSection = ({
  selectedFilters,
  onFilterChange,
  isMobileOpen,
  onMobileToggle,
}) => {
  const FilterContent = ({ isMobile = false }) => (
    <div className={isMobile ? "mt-4" : "border border-gray-200 rounded p-4"}>
      {filters.map((section) => (
        <Disclosure
          as="div"
          key={section.name}
          className={isMobile ? "border-t border-gray-200 px-4 py-6" : "mb-4"}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between ${
                  isMobile
                    ? "bg-white px-2 py-3"
                    : "rounded bg-gray-50 px-4 py-2"
                } text-left text-sm font-medium text-gray-900 hover:bg-gray-100`}
              >
                <span>{section.name}</span>
                {isMobile ? (
                  open ? (
                    <MinusIcon className="h-5 w-5" />
                  ) : (
                    <PlusIcon className="h-5 w-5" />
                  )
                ) : (
                  <ChevronDownIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-gray-500`}
                  />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="pt-6 px-4">
                <div className="space-y-6">
                  {section.options.map((option) => (
                    <div key={option.name} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={
                          selectedFilters[section.name]?.[option.name] || false
                        }
                        onChange={() =>
                          onFilterChange(section.name, option.name)
                        }
                        className="h-4 w-4 rounded border-gray-300"
                      />
                      <label className="ml-3 flex items-center">
                        {section.name === "Color" && (
                          <span
                            className="mr-2 h-4 w-4 rounded-full border"
                            style={{ backgroundColor: option.code }}
                          />
                        )}
                        <span className="text-sm text-gray-600">
                          {option.name}
                        </span>
                        {section.name === "Color" && (
                          <span className="ml-2 text-xs text-gray-400">
                            {option.code}
                          </span>
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );

  return (
    <>
      <Transition.Root show={isMobileOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={onMobileToggle}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => onMobileToggle(false)}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
                <FilterContent isMobile={true} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:block">
        <FilterContent />
      </div>
    </>
  );
};
