import { TrashIcon } from "@heroicons/react/24/outline";
import { PhotoDisplay } from "../../../../components/shared";

export const SelectedItemsTable = ({ items, onRemoveItem }) => {
  if (items.length === 0) return null;

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-24 mt-8">
      <h2 className="text-2xl font-bold mb-4">Selected Items</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {[
                "Unique Code",
                "Item",
                "Source",
                "Location",
                "Image",
                "Color",
                "Application",
                "Description",
                "Actions",
              ].map((header) => (
                <th
                  key={header}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {items.map((item) => (
              <tr key={item.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {item.uniqueCode}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.source}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.location}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-20 w-20 relative">
                    <PhotoDisplay
                      className="h-full w-full object-cover object-center rounded"
                      photoId={item.image}
                    />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <span
                      className="h-4 w-4 rounded-full border mr-2"
                      style={{ backgroundColor: item.color }}
                    />
                    <span className="text-sm text-gray-500">{item.color}</span>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 max-w-xs">
                  <p className="truncate hover:text-clip hover:whitespace-normal">
                    {item.application}
                  </p>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 max-w-xs">
                  <p className="truncate hover:text-clip hover:whitespace-normal">
                    {item.description}
                  </p>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button
                    onClick={() => onRemoveItem(item.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
