import React, { useState, useRef, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Sketch } from "@uiw/react-color";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import { Input, Toast } from "../../../components/shared";

export const ArchitectureTool = () => {
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  const [toast, setToast] = useState(null);
  const [openColorPickers, setOpenColorPickers] = useState(new Set([0]));
  const baseURL = process.env.REACT_APP_BASE_URL;

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      thingGroups: [
        {
          code: "",
          item: "",
          size: "",
          application: "",
          image: null,
          color: "#000000",
          description: "",
          source: "",
          location: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "thingGroups",
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".color-picker-container")) {
        setOpenColorPickers(new Set());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (tableData.length > 0 && tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [tableData]);

  const toggleColorPicker = (index) => {
    setOpenColorPickers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    console.log("Thing Groups:", data.thingGroups);

    try {
      // Create a FormData object to send data (including image)
      const formData = new FormData();

      // Loop through the thingGroups array and append each group's data to FormData
      data.thingGroups.forEach((group, index) => {
        // Append other fields from the group to the FormData object
        formData.append(
          "data",
          JSON.stringify({
            spec_code: group.code,
            spec_item: group.item,
            spec_source: group.source,
            spec_location: group.location,
            spec_application: group.application,
            spec_description: group.description,
            spec_size: group.size,
            spec_colour: group.color,
            user_id: "12", // Replace with actual user ID
          })
        );

        // Append the image directly if it exists
        if (group.image) {
          console.log("Appending image file:", group.image);
          formData.append(`spec_image`, group.image);
        } else {
          console.log("No image found for group", group);
        }
      });

      // Log the FormData to ensure it's being populated correctly
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": ", pair[1]);
      }

      // Send the data to the backend via Axios
      const response = await axios.post(
        `${baseURL}/api/add_spectool`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // This header is crucial for FormData
          },
        }
      );

      // Handle the response from the backend
      if (response.status === 201) {
        console.log("Spec Tool added successfully:", response.data);
        alert("Spec Tool added successfully!");
      } else {
        console.error("Error adding spec tool:", response.data);
        alert("Error adding spec tool. Please try again.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred while adding the spec tool. Please try again.");
    }
  };
  return (
    <DashboardLayout>
      <div className="h-screen overflow-y-auto">
        <Helmet>
          <title>Arch Tool | GoatArch Design and Products</title>
        </Helmet>

        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            duration={toast.duration}
            onClose={() => setToast(null)}
          />
        )}

        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-center p-6 bg-white">
            <h1 className="text-2xl font-bold">Arch Tool</h1>
          </div>

          <div className="relative pb-20">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="bg-white p-6 rounded-lg shadow-sm border"
                >
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <Input
                      name={`thingGroups.${index}.code`}
                      control={control}
                      label="Code"
                      placeholder="Enter code"
                      rules={{ required: "Code is required" }}
                    />
                    <Input
                      name={`thingGroups.${index}.item`}
                      control={control}
                      label="Item"
                      placeholder="Enter item name"
                      rules={{ required: "Item is required" }}
                    />
                    <Input
                      name={`thingGroups.${index}.size`}
                      control={control}
                      label="Size"
                      placeholder="Enter size"
                      rules={{ required: "Size is required" }}
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Input
                      name={`thingGroups.${index}.source`}
                      control={control}
                      label="Source"
                      placeholder="Enter source"
                      rules={{ required: "Source is required" }}
                    />
                    <Input
                      name={`thingGroups.${index}.location`}
                      control={control}
                      label="Location"
                      placeholder="Enter location"
                      rules={{ required: "Location is required" }}
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Input
                      name={`thingGroups.${index}.application`}
                      control={control}
                      type="textarea"
                      label="Application"
                      placeholder="Enter application details"
                      rules={{ required: "Application is required" }}
                    />
                    <Input
                      name={`thingGroups.${index}.description`}
                      control={control}
                      type="textarea"
                      label="Description"
                      placeholder="Enter description"
                      rules={{ required: "Description is required" }}
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Input
                      name={`thingGroups.${index}.image`}
                      control={control}
                      type="file"
                      label="Image"
                      accept="image/*"
                    />

                    <div className="">
                      <label className="text-sm font-medium text-gray-700 mb-1">
                        Color
                      </label>
                      <div className="color-picker-container">
                        <div className="flex flex-col gap-2">
                          <div
                            className="w-full h-12 rounded border cursor-pointer"
                            style={{
                              backgroundColor: watch(
                                `thingGroups.${index}.color`
                              ),
                            }}
                            onClick={() => toggleColorPicker(index)}
                          />
                          <div className="text-sm font-medium text-center">
                            {watch(`thingGroups.${index}.color`)}
                          </div>
                        </div>
                        {openColorPickers.has(index) && (
                          <div className="mt-2">
                            <div className="shadow-lg rounded-lg overflow-hidden">
                              <Sketch
                                color={watch(`thingGroups.${index}.color`)}
                                onChange={(color) => {
                                  setValue(
                                    `thingGroups.${index}.color`,
                                    color.hex
                                  );
                                }}
                                style={{ width: "240px" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {fields.length > 1 && (
                    <button
                      type="button"
                      onClick={() => {
                        remove(index);
                        setOpenColorPickers((prev) => {
                          const newSet = new Set(prev);
                          newSet.delete(index);
                          return newSet;
                        });
                      }}
                      className="text-red-600 hover:text-red-800"
                    >
                      Remove Group
                    </button>
                  )}
                </div>
              ))}

              <div className="sticky bottom-0 bg-white border-t p-4">
                {" "}
                {/* Changed from fixed to sticky */}
                <div className="flex justify-end bg-white border-t p-4">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-theme-color text-white rounded hover:bg-black transition-colors"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
