import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { PhotoDisplay } from "../../../../components/shared";

export const ProductGrid = ({ products, addedProducts, onAddProduct }) => {
  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
      {products.map((product) => (
        <div
          key={product.id}
          className="group relative overflow-hidden rounded shadow-md hover:shadow-xl transition-all duration-300"
        >
          <div className="relative h-80">
            <PhotoDisplay
              className="h-full w-full object-cover object-center"
              photoId={product.image}
            />
            <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors duration-300" />
            <button
              onClick={() => onAddProduct(product.id)}
              className={`absolute top-4 right-4 p-2 rounded-full transition-all duration-300 opacity-0 group-hover:opacity-100
              ${
                addedProducts.has(product.id)
                  ? "bg-green-500 hover:bg-green-600"
                  : "bg-white hover:bg-gray-100"
              }`}
            >
              <PlusCircleIcon
                className={`h-6 w-6 ${
                  addedProducts.has(product.id) ? "text-white" : "text-gray-800"
                }`}
              />
            </button>
          </div>
          <div className="absolute bottom-4 left-4 right-4">
            <h3 className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300">
              {product.name}
            </h3>
            <p className="text-white text-sm">
              {product.itemType} • {product.location}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
