import React, { useState } from "react";
import { Send, MessageSquare, User, Search } from "lucide-react";
import { SiteLogo } from "../../components/shared/index";

const INITIAL_QUERIES = [
  {
    id: 1,
    user: {
      name: "Architecture Innovations",
      avatar: "/api/placeholder/50/50",
    },
    question:
      "What are the latest sustainable architectural trends for urban development?",
    replies: [
      {
        id: 101,
        user: {
          name: "Professional Architect",
          avatar: "/api/placeholder/40/40",
        },
        text: "Green roofs and vertical gardens are becoming increasingly popular in urban settings.",
        timestamp: new Date(),
      },
    ],
    likes: 12,
  },
];

export const GoatArchForum = () => {
  const [queries, setQueries] = useState(INITIAL_QUERIES);
  const [newQuery, setNewQuery] = useState("");
  console.log("newQuery", newQuery);
  
  const [activeReplies, setActiveReplies] = useState({});
  const [replyTexts, setReplyTexts] = useState({});

  const handleQuerySubmit = (e) => {
    e.preventDefault();
    if (!newQuery.trim()) return;

    const newQueryObj = {
      id: Date.now(),
      user: {
        name: "Anonymous User",
        avatar: "/api/placeholder/50/50",
      },
      question: newQuery,
      replies: [],
      timestamp: new Date(),
      likes: 0,
    };

    setQueries([newQueryObj, ...queries]);
    setNewQuery("");
  };

  const toggleReplies = (queryId) => {
    setActiveReplies((prev) => ({
      ...prev,
      [queryId]: !prev[queryId],
    }));
  };

  const handleReplySubmit = (queryId) => {
    const replyText = replyTexts[queryId];
    if (!replyText || !replyText.trim()) return;

    const newReply = {
      id: Date.now(),
      user: {
        name: "Community Expert",
        avatar: "/api/placeholder/40/40",
      },
      text: replyText,
      timestamp: new Date(),
    };

    const updatedQueries = queries.map((query) =>
      query.id === queryId
        ? { ...query, replies: [...query.replies, newReply] }
        : query
    );

    setQueries(updatedQueries);
    setReplyTexts((prev) => ({ ...prev, [queryId]: "" }));
    setActiveReplies((prev) => ({ ...prev, [queryId]: false }));
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="bg-theme-light-bg min-h-screen">
      {/* Header - remains the same as in original code */}
      <header className="fixed top-0 left-0 right-0 bg-white border-b z-20">
        <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <SiteLogo width="150" />
          </div>

          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search questions..."
                className="pl-10 pr-4 py-2 border border-theme-color rounded-full w-64 focus:outline-none focus:ring-2 focus:ring-theme-color"
              />
              <Search
                className="absolute left-3 top-3 text-gray-400"
                size={18}
              />
            </div>
            <button className="bg-theme-color text-white px-4 py-2 rounded-full hover:bg-black transition">
              Ask a Question
            </button>
            <User className="text-gray-600" size={28} />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto pt-24 px-4">
        {/* Query Submission */}
        <form onSubmit={handleQuerySubmit} className="mb-8">
          <textarea
            value={newQuery}
            onChange={(e) => setNewQuery(e.target.value)}
            placeholder="What architectural challenge are you curious about?"
            className="w-full p-4 border-2 border-theme-color rounded-lg focus:outline-none focus:border-theme-color resize-none"
            rows="1"
          />
          <div className="flex justify-end items-center mt-4">
            <button
              type="submit"
              className="bg-theme-color text-white px-6 py-2 rounded-full hover:bg-black transition flex items-center space-x-2"
            >
              <Send size={18} />
              <span>Post Question</span>
            </button>
          </div>
        </form>

        {/* Queries List */}
        <div className="space-y-6">
          {queries.map((query) => (
            <div
              key={query.id}
              className="bg-white border border-theme-color rounded-lg p-6 shadow-sm hover:shadow-md transition"
            >
              {/* Query Header */}
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center space-x-3">
                  <img
                    src={query.user.avatar}
                    alt={query.user.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <p className="font-semibold text-gray-800">
                      {query.user.name}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatTimestamp(query.timestamp)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Query Content */}
              <h2 className="text-lg font-medium text-gray-900 mb-4 text-left">
                {query.question}
              </h2>

              {/* Query Actions */}
              <div className="flex items-center space-x-4 text-black text-sm mt-6">
                <button
                  onClick={() => toggleReplies(query.id)}
                  className="flex items-center space-x-2 hover:text-theme-color transition"
                >
                  <MessageSquare size={16} />
                  <span>{query.replies.length} Replies</span>
                </button>
                <button className="flex items-center space-x-2 hover:text-theme-color transition">
                  <span>👍</span>
                  <span>{query.likes} Likes</span>
                </button>
              </div>

              {/* Updated Replies Section */}
              {activeReplies[query.id] && (
                <div className="mt-6 pt-4 border-t">
                  {/* Existing Replies */}
                  {query.replies.map((reply) => (
                    <div
                      key={reply.id}
                      className="mb-4 p-4 bg-theme-light-bg rounded-lg border border-gray-100 space-x-3"
                    >
                      <div className="flex gap-5 justify-between items-center">
                        <div className="flex justify-center items-center gap-5">
                          <img
                            src={reply.user.avatar}
                            alt={reply.user.name}
                            className="w-10 h-10 rounded-full object-cover mt-1"
                          />
                          <p className="font-semibold text-gray-800 text-sm">
                            {reply.user.name}
                          </p>
                        </div>
                        <div className="flex gap-5 items-center justify-center">
                          <div>
                            <p className="text-xs text-gray-500">
                              {formatTimestamp(reply.timestamp)}
                            </p>
                          </div>
                          <button className="text-gray-500 hover:text-theme-color text-xs">
                            Reply
                          </button>
                        </div>
                      </div>
                      <div className="text-left mt-2">
                        <p className="text-black text-sm leading-relaxed font-medium">
                          {reply.text}
                        </p>
                      </div>
                    </div>
                  ))}

                  {/* Reply Input */}
                  <div className="flex space-x-3 items-end mt-4">
                    <textarea
                      value={replyTexts[query.id] || ""}
                      onChange={(e) =>
                        setReplyTexts((prev) => ({
                          ...prev,
                          [query.id]: e.target.value,
                        }))
                      }
                      placeholder="Write a detailed reply..."
                      className="w-full p-3 border border-theme-color rounded-lg focus:outline-none focus:border-theme-color resize-none bg-white"
                      rows="1"
                    />
                    <button
                      onClick={() => handleReplySubmit(query.id)}
                      className="bg-theme-color text-white p-3 rounded-lg hover:bg-black transition h-fit"
                    >
                      <Send size={20} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
