import React, { useState, useEffect } from "react";
import { Layout } from "../../components/layout/layout";
import {
  BreadcrumbComponentTwo,
  SEO,
  Loader,
  PhotoDisplay,
} from "../../components/shared";
import CompanyCard from "../Company Browsing/CompanyCard";
import axios from "axios";
import BrandsImage from "../../assets/images/Brands/brand-v-3.jpg";
export const Brands = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/companies`
      );

      // Filter companies where primary_company_type is exactly "Brand"
      const brandCompanies = response.data.filter(
        (company) => company.primary_company_type === "Brand"
      );

      setCompanies(brandCompanies);
      setFilteredCompanies(brandCompanies);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch companies");
      console.error("Error fetching companies:", err);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = companies.filter(
      (company) =>
        company.company_name.toLowerCase().includes(value) ||
        (company.country && company.country.toLowerCase().includes(value)) ||
        (company.city && company.city.toLowerCase().includes(value))
    );

    setFilteredCompanies(filtered);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredCompanies(companies);
  };

  const shareURL = "https://goatarch.com/static/media/brands.jpg";

  return (
    <Layout headerType="2">
      <SEO
        canonicalUrl="https://goatarch.com/brands/"
        description="Discover top brand designers on GoatArch. Explore innovative branding professionals and their creative work."
        title="Brand Designers"
        keywords={[
          "brand designers",
          "branding professionals",
          "creative branding",
          "brand identity",
          "design branding",
        ]}
        image={shareURL}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "Brand Designers | GoatArch",
          description:
            "Discover top brand designers on GoatArch. Explore innovative branding professionals and their creative work.",
          url: "https://goatarch.com/brands/",
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />
      <BreadcrumbComponentTwo bgImage={BrandsImage} title={"Brands"} />

      <section className="px-6 lg:px-24 py-16 md:py-16">
        <div className="mb-12">
          <h2
            className="font-bold text-theme-color capitalize text-center"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            <span className="text-black">Discover Materials & </span>Brands
          </h2>
          <div className="mt-6 text-center">
            Find creative professionals who specialize in brand design and
            identity.
          </div>
        </div>

        {/* Search Bar */}
        <div className="mb-8 max-w-xl mx-auto">
          <div className="relative">
            <input
              type="text"
              placeholder="Search brands, designers, locations..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-theme-color"
            />
            {searchTerm && (
              <button
                onClick={clearSearch}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-theme-color"
              >
                ✕
              </button>
            )}
          </div>
        </div>

        {/* Companies Grid */}
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : error ? (
          <div className="text-center text-red-500">{error}</div>
        ) : filteredCompanies.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredCompanies.map((company) => (
              <CompanyCard key={company.id} company={company} />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No brand designers found. Try a different search.
          </div>
        )}
      </section>
    </Layout>
  );
};

export default Brands;
