import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon, PlayIcon } from "@heroicons/react/24/outline";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube
} from "react-icons/fa";
import { PhotoDisplay } from "../../../components/shared";
import { color } from "framer-motion";

const socialIcons = [
  {
    Icon: FaFacebookF,
    alt: "Facebook",
    link: "/",
    color: "#1877F2",
    hoverColor: "#0e5ccc",
  },
  {
    Icon: FaInstagram,
    alt: "Instagram",
    link: "https://www.instagram.com/goatarch_community/",
    color: "#E4405F",
    hoverColor: "#d62e50",
  },
  {
    Icon: FaYoutube,
    alt: "YouTube",
    link: "https://www.youtube.com/@GoatArchCommunity",
    color: "#FF0234",
    hoverColor: "#FF0234",
  },
  {
    Icon: FaLinkedinIn,
    alt: "LinkedIn",
    link: "https://www.linkedin.com/company/goatarch-community/",
    color: "#0A66C2",
    hoverColor: "#084c8f",
  },
];

export const AboutSectionOne = () => {
  return (
    <section className="relative mt-16 px-6 lg:px-24">
      <div className="py-8 lg:py-16">
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16">
          <div className="lg:w-1/2">
            <h1 className="mb-6 z-[999]">
              Welcome to GoatArch{" "}
              <span className="text-theme-color">
                {" "}
                Greatest of all time Architecture - The Community
              </span>
            </h1>
            <div className="flex items-center gap-4">
              <div className="w-20 h-px bg-gray-300"></div>
              <div className="flex items-center gap-6">
                {socialIcons.map((icon, index) => (
                  <a
                    key={index}
                    className="transition-transform duration-200 hover:-translate-y-1"
                    href={icon.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <icon.Icon
                      className={`w-6 h-6 transition-colors duration-200 text-${icon-color}`}
                      style={{
                        filter: `drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1))`,
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.color = icon.hoverColor)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.color = icon.color)
                      }
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <p className="text-black mb-6">
              We showcase Projects of Architects and Designers from around the
              globe. We connect the design and construction industry by
              providing a platform to display their works, products and
              services.
            </p>
            <div className="flex items-center gap-4">
              <Link
                className="flex items-center px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors"
                to="/register"
              >
                Get Started
                <ArrowRightIcon className="w-5 ml-2 text-white" />
              </Link>
              <div className="flex items-center gap-2">
                <PlayIcon className="text-theme-color w-8" />
                <a className="font-medium" href="#process">
                  Our Process
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 mt-8 lg:mt-16">
          {["rAPI9764v7U", "ljp-ewA23lc", "DYqp59DkJBY"].map(
            (photoId, index) => (
              <div key={index} className="flex-1">
                <PhotoDisplay
                  className="object-cover w-full h-[250px] lg:h-[650px] rounded"
                  photoId={photoId}
                />
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};
