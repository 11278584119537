import React, { useEffect, useState } from "react";
import { SiteLogo, Input, Popup } from "../../shared/index";
import { Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import OzoneLogo from "../../../assets/images/Logo/ozone/techozone_log-white.png";

// Centralized link data
const footerLinks = {
  quickLinks: [
    { to: "/", label: "Home" },
    { to: "/about-us/", label: "About Us" },
    { to: "/events/", label: "Events" },
    { to: "/contact-us", label: "Contact" },
  ],
  topIndustries: [
    { to: "/companies#architects", label: "Architect" },
    { to: "/companies#interior", label: "Interior Design" },
    { to: "/companies#mep", label: "MEP Consultant" },
    { to: "/companies#landscape", label: "Landscape Designer" },
    { to: "/companies#urban", label: "Urban Design and Planners" },
  ],
  services: [
    { to: "/projects", label: "Project" },
    { to: "/products", label: "Products" },
    { to: "/companies", label: "Company Listing" },
    { to: "/dashboard/create-projects/", label: "Submit Projects" },
    { to: "/dashboard/create-products/", label: "Showcase Products" },
  ],
  otherLinks: [
    { to: "/terms-and-conditions", label: "Terms & Conditions" },
    { to: "/terms-of-uses", label: "Terms of Uses" },
    { to: "/privacy-policy", label: "Privacy Policy" },
    { to: "/return-and-refund-policy", label: "Return & Refund Policy" },
  ],
};

const serviceOptions = [
  { value: "web_app_design", label: "Web Application Design" },
  { value: "website_design", label: "Website Design" },
  { value: "ecommerce_design", label: "E-commerce Website Design" },
  { value: "digital_marketing_seo", label: "Digital Marketing and SEO" },
  {
    value: "social_media_marketing",
    label: "Social Media Marketing and Management",
  },
];

export const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { control, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch("https://formspree.io/f/mvgoongr", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      setSubmitMessage(
        response.ok
          ? "Form submitted successfully"
          : "Failed to submit form. Please try again."
      );
    } catch (error) {
      console.error("There was an error submitting the form", error);
      setSubmitMessage("Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const renderLinkSection = (title, links) => (
    <div>
      <p className="font-semibold text-white">{title}</p>
      <div className="flex flex-col items-start mt-5 space-y-2">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="text-gray-300 transition-colors duration-300 hover:text-theme-color hover:underline"
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <footer className="bg-black">
      <div className="px-6 lg:px-24 py-12 mx-auto">
        <div className="md:flex md:-mx-3 md:items-center md:justify-between">
          <h5
            className="font-semibold tracking-tight text-white md:mx-3 xl:text-2xl"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            The GoatArch team thanks you for visiting . . .
          </h5>
          <div className="mt-6 md:mx-3 shrink-0 md:mt-0 md:w-auto">
            <Link
              to="/register/"
              className="flex justify-center items-center gap-2 w-64 text-white bg-theme-color hover:bg-black hover:border-theme-color border-2 border-black hover:text-theme-color transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px] rounded"
            >
              <span>Register Now</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <hr className="my-6 border-gray-700 md:my-10" />
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          {renderLinkSection("Quick Link", footerLinks.quickLinks)}
          {renderLinkSection(
            "Top Industries Listed",
            footerLinks.topIndustries
          )}
          {renderLinkSection("Services", footerLinks.services)}
          {renderLinkSection("Other Link", footerLinks.otherLinks)}
          <div>
            <p className="font-semibold text-white">Our Technology Partner</p>
            <div className="flex flex-col items-start mt-5 space-y-2">
              <a
                className="cursor-pointer"
                href="https://techozone.pro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={OzoneLogo} alt="Ozone Softwares" className="" />
              </a>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-700 md:my-10" />
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <SiteLogo className="w-52" white={true} />
          <p className="mt-4 text-sm text-gray-300 sm:mt-0">
            © Copyright <span id="currentyear">{new Date().getFullYear()}</span>
            . All Rights Reserved.
          </p>
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <div className="w-full lg:w-[550px] h-[50vh] max-w-2xl mx-auto">
          <h2 className="text-2xl font-bold mb-6 text-center">Contact Ozone</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              <Input
                name="name"
                control={control}
                rules={{ required: "Name is required" }}
                label="Name"
                placeholder="Enter your name"
              />
              <Input
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                label="Email"
                type="email"
                placeholder="Enter your email"
              />
            </div>
            <Input
              name="service"
              control={control}
              rules={{ required: "Please select a service" }}
              label="Services I want"
              type="select"
              options={serviceOptions}
              placeholder="Select a service"
            />
            {submitMessage && (
              <p
                className={`text-sm mb-4 ${
                  submitMessage.includes("successfully")
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {submitMessage}
              </p>
            )}
            <button
              type="submit"
              className="w-full bg-theme-color text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors duration-200"
            >
              Submit
            </button>
          </form>
        </div>
      </Popup>
    </footer>
  );
};
