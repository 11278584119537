import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Building2,
  Package,
  Upload,
  Building as Buildings,
  Newspaper,
  Calendar,
  ChevronRight,
} from "lucide-react";
import { SiteLogo } from "../components/shared";
import { Link } from "react-router-dom";

const links = [
  {
    text: "Explore GoatArch",
    icon: <Building2 className="w-6 h-6" />,
    tooltip: "Discover the best architecture and design professionals",
    url: "/goatarch-home/",
  },
  {
    text: "Showcase products",
    icon: <Package className="w-6 h-6" />,
    tooltip: "Present your products to potential clients",
    url: "/dashboard/create-products/",
  },
  {
    text: "Submit project",
    icon: <Upload className="w-6 h-6" />,
    tooltip: "Share your architectural projects with the community",
    url: "/dashboard/create-projects/",
  },
  {
    text: "List my company",
    icon: <Buildings className="w-6 h-6" />,
    tooltip: "Connect with leading companies in the industry",
    url: "/register/",
  },
  {
    text: "Articles",
    icon: <Newspaper className="w-6 h-6" />,
    tooltip: "Stay updated with the latest industry insights",
    url: "/articles/",
  },
  {
    text: "Add your events",
    icon: <Calendar className="w-6 h-6" />,
    tooltip: "Share and discover industry events",
    url: "/events/",
  },
];

export const GoatArchGetStarted = () => {
  const [showContent, setShowContent] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <AnimatePresence>
        {!showContent ? (
          <motion.div
            key="intro"
            className="h-screen flex items-center justify-center relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5 }}
          >
            {/* Background Animation */}
            <motion.div
              className="absolute inset-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 0.1, 0] }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 blur-3xl" />
            </motion.div>

            {/* Animated Lines */}
            <div className="absolute inset-0 overflow-hidden">
              {[...Array(5)].map((_, i) => (
                <motion.div
                  key={i}
                  className="absolute h-px w-full bg-gradient-to-r from-transparent via-black/20 to-transparent"
                  style={{ top: `${20 * (i + 1)}%` }}
                  initial={{ x: "-100%" }}
                  animate={{ x: "100%" }}
                  transition={{
                    duration: 3,
                    delay: i * 0.2,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                />
              ))}
            </div>

            {/* Logo Animation */}
            <motion.div className="relative z-10 text-center">
              <motion.div
                className="relative inline-block"
                initial={{ scale: 1.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1, ease: "easeOut" }}
              >
                <motion.h1 className="text-[120px] font-black leading-none tracking-tighter">
                  <motion.span
                    initial={{ x: -100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="bg-gradient-to-r from-black to-black bg-clip-text text-transparent"
                  >
                    Goat
                  </motion.span>
                  <motion.span
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="bg-gradient-to-r from-theme-color to-theme-color bg-clip-text text-transparent"
                  >
                    Arch
                  </motion.span>
                </motion.h1>

                {/* Animated Border */}
                <motion.div
                  className="absolute -inset-4 border border-black/10 rounded-xl"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                />

                {/* Glowing Effect */}
                <motion.div
                  className="absolute -inset-2 bg-blue-500/20 blur-xl rounded-full"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0, 1, 0] }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
              </motion.div>
            </motion.div>
          </motion.div>
        ) : (
          <div className="flex flex-col min-h-screen">
            <main className="flex-grow flex flex-col items-center justify-center px-4 py-12">
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center mb-6"
              >
                <div className="flex justify-center items-center mb-6">
                  <SiteLogo height={250} width={250} white={false} />
                </div>
                <p className="text-gray-700 max-w-2xl mx-auto">
                Welcome! <Link className="text-black" to={'/goatarch-home/'}>GoatArch.com</Link> is an Unique Platform to Connect Companies and Showcase their work in the World of Design and Construction.
                </p>
              </motion.div>

              {/* Capsule Button */}
              <motion.button
                onClick={() => setShowLinks(!showLinks)}
                className="mb-16 px-8 py-3 bg-black text-white rounded-full flex items-center gap-2 hover:bg-gray-800 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                I like to do...
                <ChevronRight
                  className={`w-5 h-5 transition-transform ${
                    showLinks ? "rotate-90" : ""
                  }`}
                />
              </motion.button>

              <AnimatePresence>
                {showLinks && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    className="flex justify-center gap-4 overflow-x-auto pb-4 w-screen px-4"
                  >
                    {links.map((link, index) => (
                      <Link to={link.url} key={index}>
                        <motion.div
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="relative"
                          onMouseEnter={() => setHoveredLink(index)}
                          onMouseLeave={() => setHoveredLink(null)}
                        >
                          <motion.div
                            whileHover={{ scale: 1.05 }}
                            className="flex items-center gap-3 bg-white border border-gray-200 rounded-lg px-6 py-4 shadow-sm hover:shadow-md transition-all cursor-pointer whitespace-nowrap"
                          >
                            {link.icon}
                            <span className="text-gray-800">{link.text}</span>
                          </motion.div>

                          {/* <AnimatePresence>
                            {hoveredLink === index && (
                              <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 10 }}
                                className="absolute z-10 bg-gray-900 text-white text-sm px-4 py-2 rounded-md -bottom-12 left-1/2 transform -translate-x-1/2 w-max max-w-xs"
                              >
                                {link.tooltip}
                              </motion.div>
                            )}
                          </AnimatePresence> */}
                        </motion.div>
                      </Link>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </main>

            <footer className="py-8 bg-white/50 backdrop-blur-sm">
              <motion.div
                initial={{ x: "100%" }}
                animate={{ x: "-100%" }}
                transition={{
                  repeat: Infinity,
                  duration: 20,
                  ease: "linear",
                }}
                className="text-base font-medium text-transparent bg-clip-text bg-gray-400 whitespace-nowrap"
              >
                GoatArch • Connecting Architecture Professionals • Build Your
                Digital Presence • Showcase Your Expertise • Grow Your Business
                • Innovation in Design •
              </motion.div>
            </footer>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
