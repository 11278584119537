import React, { useState, useRef } from "react";
import { Popup, AnimatedSpinner } from "../../../components/shared";

export const OtpVerificationPopup = ({ isOpen, onClose, onVerify, email }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const otpInputRefs = useRef([]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const updatedOtp = [...otp];
    updatedOtp[index] = element.value;
    setOtp(updatedOtp);

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleBackspace = (e, index) => {
    e.preventDefault();

    const updatedOtp = [...otp];
    if (updatedOtp[index]) {
      updatedOtp[index] = "";
      setOtp(updatedOtp);
    } else if (index > 0) {
      updatedOtp[index - 1] = "";
      setOtp(updatedOtp);
      otpInputRefs.current[index - 1]?.focus();
    }
  };

  const handleVerify = async () => {
    const trimmedOtp = otp.join("").trim();
    setIsLoading(true);

    try {
      const success = await onVerify(email, trimmedOtp);
      if (!success) {
        setError("Error verifying OTP. Please try again.");
      }
    } catch (error) {
      setError("Network error. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} showCloseIcon={false}>
      <div className="flex flex-col items-center justify-center space-y-4 p-6">
        <h2 className="text-xl font-bold">Email Verification</h2>
        <p className="text-gray-600 text-sm text-center">
          Enter the 6-digit OTP sent to your email.
        </p>
        <div className="flex justify-center space-x-2">
          {otp.map((value, index) => (
            <input
              key={index}
              ref={(ref) => (otpInputRefs.current[index] = ref)}
              className="w-10 h-12 border-2 rounded bg-gray-100 border-gray-300 text-center text-xl outline-none focus:border-theme-color"
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  handleBackspace(e, index);
                }
              }}
              inputMode="numeric"
            />
          ))}
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        {isLoading ? (
          <button
            disabled
            className="px-6 py-2 flex items-center gap-2 justify-center bg-black text-white transition-all duration-200 rounded relative"
          >
            <span className="text-white">Verifying</span>
            <AnimatedSpinner size={{ width: 24, height: 24 }} />
          </button>
        ) : (
          <button
            onClick={handleVerify}
            className="px-6 py-2 bg-theme-color text-white hover:bg-black transition-all duration-200 rounded relative"
          >
            Verify
          </button>
        )}
      </div>
    </Popup>
  );
};
