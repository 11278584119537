import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Layout } from "../../components/layout/layout";
import { CompanyProjects } from "./Company Projects/projects";
import { CompanyProducts } from "./Company Products/products";
import { ExpertiseSection } from "./Expertise";
import { BrandsSection } from "./Brands";
import EnquiryForm from "../../components/common/Enquiry Form";
import defaultLogo from "../../assets/images/Logo/GA-Logo-black.png";
import { HashtagsSection } from "../../components/common/Hashtags";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { SimiliarCompanies } from "./Similiar Company";
import {
  UniversalSocialShare,
  Loader,
  Popup,
  SEO,
  ImageDisplay,
} from "../../components/shared";

export const CompanyProfile = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const overviewRef = useRef(null);
  const projectsRef = useRef(null);
  const productsRef = useRef(null);
  const brochureRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(
    "https://www.youtube.com/embed/D0UnqGm_miA"
  );
  const { id } = useParams();

  const [getCompanyDetails, setGetCompanyDetails] = useState([]);
  const [projectProductData, setProjectProductData] = useState([]);
  const [isPdfPopupOpen, setIsPdfPopupOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);

  const scrollToSection = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.post(`${baseURL}/api/companies/${id}`, {
          headers: { "Content-Type": "application/json" },
        });
        setGetCompanyDetails(response.data.company);
        setProjectProductData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [id]);

  if (loading) {
    return (
      <Layout headerType="2">
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout headerType="2">
        <div className="flex justify-center items-center h-screen">
          <p>Error loading company profile. Please try again later.</p>
        </div>
      </Layout>
    );
  }
  const formatWebsiteUrl = (url) => {
    if (!url) return null;
    url = url.trim();
    url = url.replace(/\/+$/, "");
    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }
    url = url.replace(/^https?:\/\/https?:\/\//, "https://");

    return url;
  };

  const extractCleanDomain = (url) => {
    if (!url) return null;

    try {
      let cleanUrl = url.replace(/^https?:\/\//, "");

      cleanUrl = cleanUrl.replace(/\/+$/, "");
      cleanUrl = cleanUrl.split("/")[0];
      return cleanUrl;
    } catch (error) {
      console.error("Error extracting domain:", error);
      return null;
    }
  };

  const sidebarValueItems = [
    {
      label: "Category",
      value: `${getCompanyDetails?.primary_company_type}, ${getCompanyDetails?.secondary_company_type}`,
    },
    {
      label: "Location",
      value: getCompanyDetails?.city + ", " + getCompanyDetails?.country,
    },
    {
      label: "Year of Establishment",
      value: getCompanyDetails?.year_of_establishment,
    },
    // { label: "Total Projects", value: "45", valueClass: "text-red-600" },
    // { label: "Total Products", value: "164" },
    { label: "Total Value", value: getCompanyDetails?.annual_revenue },
    // { label: "Total Employees", value: getCompanyDetails?.number_of_employees },
    {
      label: "Website",
      value: {
        displayText: extractCleanDomain(getCompanyDetails?.company_website),
        fullUrl: formatWebsiteUrl(getCompanyDetails?.company_website),
      },
    },
  ];

  const filteredSidebarValueItems = sidebarValueItems.filter((item) => {
    // Check for null, undefined, empty strings, and empty arrays
    if (item.value === null || item.value === undefined) return false;
    if (typeof item.value === "string" && item.value.trim() === "")
      return false;
    if (Array.isArray(item.value) && item.value.length === 0) return false;
    // Handle special case for website URL
    if (item.label === "Website" && (!item.value || item.value === "https://"))
      return false;
    return true;
  });
  const socialMediaLinks = [
    {
      href: getCompanyDetails.company_social_media?.facebookUrl || null,
      icon: FaFacebook,
      hoverColor: "hover:text-blue-600",
    },
    {
      href: getCompanyDetails.company_social_media?.twitterUrl || null,
      icon: FaTwitter,
      hoverColor: "hover:text-blue-400",
    },
    {
      href: getCompanyDetails.company_social_media?.linkedinUrl || null,
      icon: FaLinkedin,
      hoverColor: "hover:text-blue-700",
    },
    {
      href: getCompanyDetails.company_social_media?.instagramUrl || null,
      icon: FaInstagram,
      hoverColor: "hover:text-pink-600",
    },
    {
      href: getCompanyDetails.company_social_media?.youtubeUrl || null,
      icon: FaYoutube,
      hoverColor: "hover:text-red-600",
    },
  ];

  const hashtagsData = [
    "#goatarchdesigns",
    "#goatarchproducts",
    "#goatarchconsultants",
    "#goatarchbranding",
  ];

  // Check if at least one link is valid
  const hasValidLink = socialMediaLinks.some((link) => link.href !== null);

  const getTabs = () => {
    const tabs = [
      { name: "Overview", ref: overviewRef },
      {
        name: "Brochure",
        ref: brochureRef,
        onClick: () => setIsPdfPopupOpen(true),
      },
    ];

    if (isManufacturer) {
      tabs.push({ name: "Products", ref: productsRef });
    } else {
      tabs.push({ name: "Projects", ref: projectsRef });
    }

    return tabs;
  };

  const BannerImage = getCompanyDetails?.banner_image;

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20").replace(/\(/g, "%28").replace(/\)/g, "%29");
  };

  const encodedBannerImage = BannerImage ? encodeUrl(BannerImage) : "";
  // console.log("encodedBannerImage", encodedBannerImage);

  const isManufacturer =
    getCompanyDetails?.primary_company_type?.toLowerCase() === "manufacturer" ||
    "brand";

  return (
    <Layout headerType="2">
      <SEO
        title={getCompanyDetails?.company_name}
        description={getCompanyDetails?.company_description}
        canonicalUrl={`https://goatarch.com/company-profile/${
          getCompanyDetails.id
        }-${getCompanyDetails?.company_name.replace(/\s+/g, "-")}/`}
        image={getCompanyDetails?.company_logo_image || defaultLogo}
        keywords={[
          getCompanyDetails?.company_name,
          getCompanyDetails?.primary_company_type,
          "architecture",
          "design",
        ]}
        structuredData={{
          "@type": "Organization",
          // Additional organization-specific structured data
        }}
      />
      <div className="relative mt-[88px] h-[65vh]">
        {encodedBannerImage ? (
          <img
            src={encodedBannerImage}
            alt="Banner"
            className="w-full h-full object-cover"
          />
        ) : (
          <p>No image available</p>
        )}
      </div>

      {/* Main content */}
      <div className="px-6 lg:px-24 py-8">
        <div className="flex justify-between border-b border-gray-lighter pb-6 md:pb-8 2xl:pb-10">
          <div>
            <h1 className="capitalize">{getCompanyDetails?.company_name}</h1>
            <p className="text-black">
              {getCompanyDetails?.primary_company_type}
            </p>
          </div>
          <div className="relative flex items-center gap-3">
            <UniversalSocialShare />
            <button
              type="button"
              className="inline-flex font-medium items-center justify-center focus:outline-none transition duration-200 active:scale-90 px-2.5 py-1 text-xs rounded-full bg-transparent border border-gray-300 hover:enabled:border-gray-1000 focus:enabled:border-gray-1000 focus:ring-gray-900/30 !border-none !bg-gray-lightest !p-4 text-gray-dark hover:!bg-gray-dark hover:bg-theme-color"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-auto w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-8 mt-10">
          {/* Main content area */}
          <div className="w-full lg:w-3/4">
            <section>
              <div>
                <div className="w-full">
                  {/* Tabs */}
                  <div className="" id="tabtop">
                    <div className="flex flex-wrap justify-start gap-3 lg:gap-6 py-4">
                      {getTabs()?.map((tab) => (
                        <button
                          key={tab?.name}
                          className="text-base md:text-lg font-medium text-black"
                          onClick={() => {
                            if (tab?.onClick) {
                              tab?.onClick();
                            } else {
                              scrollToSection(tab?.ref);
                            }
                          }}
                        >
                          {tab?.name}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Tab Content */}
                  <div ref={overviewRef}>
                    <section className="">
                      <div className="">
                        <div className="agent-content">
                          <div className="agent-about">
                            <h1
                              className="mt-8 text-2xl md:text-3xl font-bold"
                              style={{ fontFamily: "Lato, sans-serif" }}
                            >
                              About Company
                            </h1>
                            <div
                              className="mt-4"
                              dangerouslySetInnerHTML={{
                                __html: getCompanyDetails?.company_description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* <ProfileScoreCard
                      companyDetails={{
                        ...getCompanyDetails,
                        products: projectProductData.products,
                        projects: projectProductData.projects,
                      }}
                      isOwnProfile={
                        parsedData?.user_id === getCompanyDetails?.user_id
                      }
                      isManufacturer={isManufacturer}
                    /> */}

                    {getCompanyDetails?.company_expertise?.length > 0 && (
                      <ExpertiseSection
                        expertiseData={getCompanyDetails?.company_expertise}
                      />
                    )}
                    {getCompanyDetails?.brands_we_deal?.length > 0 && (
                      <BrandsSection
                        brandsData={getCompanyDetails?.brands_we_deal}
                      />
                    )}
                    {getCompanyDetails?.gallery_images?.length > 0 && (
                      <ImageDisplay
                        SectionHeading={"Works"}
                        images={getCompanyDetails?.gallery_images}
                      />
                    )}
                  </div>

                  {!isManufacturer &&
                    projectProductData.projects?.length > 0 && (
                      <div ref={projectsRef}>
                        <section>
                          <CompanyProjects
                            projects={projectProductData?.projects}
                          />
                        </section>
                      </div>
                    )}

                  {isManufacturer &&
                    projectProductData.products?.length > 0 && (
                      <div ref={productsRef}>
                        <section>
                          <CompanyProducts
                            products={projectProductData?.products}
                          />
                        </section>
                      </div>
                    )}
                </div>
              </div>
            </section>
          </div>
          {/* Company Sidebar */}
          <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
            {/* Admin section */}
            {/* Main sidebar content */}
            <div className="rounded shadow-lg bg-white overflow-hidden border border-gray-200">
              {/* Company header */}
              <div
                className={`p-6 text-white relative overflow-hidden ${
                  isManufacturer ? "bg-black" : "bg-theme-color"
                }`}
              >
                <div className="absolute top-0 right-0 w-24 h-24 bg-white opacity-10 rounded-full transform translate-x-1/3 -translate-y-1/3"></div>
                <div className="absolute bottom-0 left-0 w-16 h-16 bg-white opacity-10 rounded-full transform -translate-x-1/3 translate-y-1/3"></div>
                <div className="flex items-center mb-4 relative z-10">
                  <img
                    src={getCompanyDetails?.company_logo_image}
                    alt="Company Logo"
                    className="w-20 h-20 object-contain rounded-full bg-white mr-4 shadow-md p-2"
                  />
                  <div>
                    <h2 className="text-xl md:text-2xl font-bold capitalize">
                      {getCompanyDetails?.company_name}
                    </h2>
                  </div>
                </div>
              </div>

              {/* Company details */}
              <div className="p-6">
                <h3
                  className={`text-lg font-semibold mb-4 pb-2 border-b-2 inline-block ${
                    isManufacturer
                      ? "text-black border-white"
                      : "text-gray-800 border-theme-color"
                  }`}
                >
                  Company Details
                </h3>
                <ul className="space-y-3">
                  {filteredSidebarValueItems.map((item, index) => {
                    // Special handling for Website to ensure it's only shown when a valid URL exists
                    if (item.label === "Website") {
                      // Check if website value exists and has a displayable text
                      if (
                        item.value &&
                        typeof item.value === "object" &&
                        item.value.displayText &&
                        item.value.displayText.trim() !== ""
                      ) {
                        return (
                          <li
                            key={index}
                            className="flex flex-col py-2 border-b border-gray-200 last:border-b-0 hover:bg-gray-50 transition duration-300"
                          >
                            <span className="text-sm text-gray-600 mb-1">
                              Website
                            </span>
                            <a
                              href={item.value.fullUrl || "#"}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm font-semibold text-theme-color break-words"
                            >
                              {item.value.displayText}
                            </a>
                          </li>
                        );
                      }
                      // If no valid website, return null to skip rendering
                      return null;
                    }

                    // Regular rendering for other items
                    return (
                      <li
                        key={index}
                        className="flex flex-col py-2 border-b border-gray-200 last:border-b-0 hover:bg-gray-50 transition duration-300"
                      >
                        <span className="text-sm text-gray-600 mb-1">
                          {item.label}
                        </span>
                        <span
                          className={`text-sm font-semibold ${
                            item.valueClass || "text-black"
                          } break-words`}
                        >
                          {item.value}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* Executive team */}
              {Array.isArray(getCompanyDetails?.number_of_employees) &&
                getCompanyDetails.number_of_employees.length > 0 && (
                  <div
                    className={`p-6 ${
                      isManufacturer ? "bg-gray-900" : "bg-gray-50"
                    }`}
                  >
                    <h3
                      className={`text-lg font-semibold mb-4 pb-2 border-b-2 inline-block ${
                        isManufacturer
                          ? "text-white border-white"
                          : "text-gray-800 border-theme-color"
                      }`}
                    >
                      Executive Team
                    </h3>
                    <ul className="space-y-3">
                      {getCompanyDetails.number_of_employees.map(
                        (item, index) => (
                          <li
                            key={index}
                            className={`flex items-center text-sm p-3 rounded-md shadow-sm ${
                              isManufacturer ? "bg-gray-800" : "bg-white"
                            }`}
                          >
                            <div
                              className={`w-8 h-8 rounded-full flex items-center justify-center mr-3 ${
                                isManufacturer
                                  ? "bg-theme-color text-black"
                                  : "bg-theme-color text-white"
                              }`}
                            >
                              {item.name[0]}
                            </div>
                            <div>
                              <span
                                className={`font-bold block ${
                                  isManufacturer ? "text-white" : "text-black"
                                }`}
                              >
                                {item.name}
                              </span>
                              <span
                                className={
                                  isManufacturer
                                    ? "text-gray-400"
                                    : "text-gray-600"
                                }
                              >
                                {item.designation}
                              </span>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}

              {/* Social media links */}
              {hasValidLink && socialMediaLinks.some((link) => link.href) && (
                <div
                  className={`flex justify-center space-x-4 p-4 ${
                    isManufacturer ? "bg-gray-800" : "bg-gray-100"
                  }`}
                >
                  {socialMediaLinks.map((item, index) => {
                    // Only render the icon if href is not null or undefined
                    if (!item.href) return null;

                    const IconComponent = item.icon;
                    return (
                      <a
                        key={index}
                        href={item.href}
                        className={`${
                          isManufacturer
                            ? "text-gray-400 hover:text-white bg-gray-700"
                            : `text-gray-400 ${item.hoverColor} bg-white`
                        } transition-colors p-2 rounded-full shadow-md`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconComponent className="w-5 h-5" />
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
            {/* Contact form */}
            <div
              className={`mt-6 rounded shadow-lg border ${
                isManufacturer
                  ? "bg-gray-900 border-gray-700 text-white"
                  : "bg-white border-gray-200"
              }`}
            >
              <EnquiryForm
                baseURL={process.env.REACT_APP_BASE_URL}
                recipientEmail={getCompanyDetails?.company_email}
                formTitle={"Contact us"}
              />
            </div>
            <div className="mt-6 relative w-full h-0 pb-[56.25%] overflow-hidden rounded">
              {getCompanyDetails.company_video_links ? (
                (() => {
                  const url = getCompanyDetails.company_video_links;
                  let videoId;

                  // Extract the video ID from the YouTube URL
                  const match = url.match(
                    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                  );
                  if (match && match[1]) {
                    videoId = match[1];
                  }

                  // Create the embed URL
                  const videoUrl = videoId
                    ? `https://www.youtube.com/embed/${videoId}`
                    : null;

                  // console.log("Video URL:", videoUrl);

                  return videoUrl ? (
                    <iframe
                      src={videoUrl}
                      title="Company Video"
                      className="absolute top-0 left-0 w-full h-full"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    <div
                      className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    >
                      <p>No video available</p>
                    </div>
                  );
                })()
              ) : (
                <div
                  className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <p>No video available</p>
                </div>
              )}
            </div>
          </div>
          {/* company Sidebar end */}
        </div>
      </div>

      <SimiliarCompanies />
      <HashtagsSection hashtags={hashtagsData} />

      <Popup isOpen={isPdfPopupOpen} onClose={() => setIsPdfPopupOpen(false)}>
        <div className="w-full h-[80vh]">
          {getCompanyDetails?.brochure_image ? (
            <iframe
              src={getCompanyDetails.brochure_image}
              title="Company Brochure"
              className="w-full h-full"
              frameBorder="0"
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full text-lg text-gray-500">
              No brochure found
            </div>
          )}
        </div>
      </Popup>
    </Layout>
  );
};
