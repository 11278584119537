import React from "react";
import { Link } from "react-router-dom";
import {
  CompanyStepOne,
  CompanyStepThree,
  CompanyStepFour,
  CompanyStepFive,
  CompanyStepSeven,
  CompanyStepEight,
} from "./Steps";
import {
  Popup,
  SiteLogo,
  Input,
  Toast,
  SEO,
  LoaderTwo,
  AnimatedSpinner,
} from "../../components/shared";
import { HomeIcon } from "@heroicons/react/24/outline";
import { LogInIcon } from "lucide-react";
import companyImage1 from "../../assets/images/Register/registerpage.jpeg";
import { useCompanyProfileSetup } from "./useCompanyProfileSetup";
import { OtpVerificationPopup } from "./OTP";

const steps = [
  { title: "Company Details", component: CompanyStepOne, image: companyImage1 },
  {
    title: "Contact & Address Details",
    component: CompanyStepThree,
    image: companyImage1,
  },
  {
    title: "About Company",
    component: CompanyStepFour,
    image: companyImage1,
  },
  {
    title: "Profile Media",
    component: CompanyStepFive,
    image: companyImage1,
  },
  {
    title: "Gallery Or Work",
    component: CompanyStepSeven,
    image: companyImage1,
  },
  {
    title: "Company Social Media Handles",
    component: CompanyStepEight,
    image: companyImage1,
  },
];

export const CompanyProfileSetup = () => {
  const {
    currentStep,
    isPopupOpen,
    isOtpPopupOpen,
    isLoading,
    emailVerified,
    errorMessage,
    isVerifyEmailLoading,
    showToast,
    submissionState,
    control,
    errors,
    watchPassword,
    watchEmail,
    watchUsername,
    setIsPopupOpen,
    setIsOtpPopupOpen,
    setShowToast,
    handleSubmit,
    sendOtp,
    handleVerifyOtp,
    onSubmit,
    prevStep,
    setSubmissionState,
  } = useCompanyProfileSetup(steps);

  const renderStep = () => {
    if (currentStep === -1) {
      return (
        <div>
          <Input
            name="username"
            control={control}
            rules={{ required: "This field is required" }}
            label="Company Name"
            placeholder="Acme Corporation"
          />
          <Input
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            label="Email"
            type="email"
            placeholder="info@acmecorp.com"
            disabled={emailVerified}
          />
          {emailVerified && (
            <>
              <Input
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                }}
                label="Password"
                type="password"
                placeholder="********"
                showPasswordStrength={true}
              />
              <Input
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watchPassword || "Passwords do not match",
                }}
                label="Confirm Password"
                type="password"
                placeholder="********"
              />
            </>
          )}
        </div>
      );
    }

    const CurrentStepComponent = steps[currentStep]?.component;
    return CurrentStepComponent ? (
      <CurrentStepComponent control={control} errors={errors} />
    ) : null;
  };

  const renderButtons = () => {
    if (currentStep === -1) {
      if (!emailVerified) {
        return (
          <button
            type="button"
            onClick={sendOtp}
            className={`w-full text-white font-bold shadow-sm py-[13px] relative cursor-pointer transition-colors duration-200 ${
              isVerifyEmailLoading
                ? "bg-black hover:bg-black"
                : "bg-theme-color hover:bg-black"
            }`}
            disabled={
              isVerifyEmailLoading ||
              !watchEmail ||
              !watchUsername ||
              errors.email ||
              errors.username
            }
          >
            {isVerifyEmailLoading ? (
              <div className="flex gap-2 justify-center items-center">
                <span className="">Please wait</span>
                <AnimatedSpinner size={{ width: 24, height: 24 }} />
              </div>
            ) : (
              "Verify Email"
            )}
          </button>
        );
      }
      return (
        <button
          type="submit"
          className="w-full bg-theme-color text-white hover:bg-black transition-all duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
          disabled={isLoading}
        >
          Next
        </button>
      );
    }

    return (
      <div className="flex justify-between gap-6 mt-6">
        <button
          type="button"
          onClick={prevStep}
          className="w-full text-white bg-black hover:bg-theme-color transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
        >
          Previous
        </button>
        <button
          type="submit"
          className="w-full text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
          disabled={isLoading}
        >
          {currentStep < steps.length - 1 ? "Next" : "Submit"}
        </button>
      </div>
    );
  };

  const renderPopupContent = () => {
    if (submissionState === "submitting") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <LoaderTwo text={"Submiting"} />
          <p className="text-red-600 text-lg md:text-xl font-semibold text-center">
            Please do not refresh or leave this page
          </p>
        </div>
      );
    }

    if (submissionState === "success") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#4BB543] font-bold text-center">
            Success!
          </h2>
          <p className="text-gray-600 text-lg md:text-xl lg:text-2xl text-center">
            Thank you for registering your Company in GoatArch Community.
          </p>
          <p className="text-base text-black text-center">
            Your profile is under review of administration. Once your profile is
            approved, you will receive an email, then you can login and enjoy
            our services.
          </p>
          <p className="text-sm text-gray-600 text-center">
            Redirecting to home page...
          </p>
        </div>
      );
    }

    if (submissionState === "error") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center text-red-600">
            Error
          </h2>
          <p className="text-gray-600 text-lg text-center">
            There was an error submitting your registration. Please try again.
          </p>
          <button
            onClick={() => {
              setIsPopupOpen(false);
              setSubmissionState("idle");
            }}
            className="px-6 py-2 bg-theme-color text-white rounded hover:bg-black transition-all"
          >
            Close
          </button>
        </div>
      );
    }
  };

  const getCurrentBackgroundImage = () => {
    if (currentStep === -1) return companyImage1;
    return steps[currentStep]?.image || companyImage1;
  };

  return (
    <div>
      <SEO
        canonicalUrl={"https://goatarch.com/register"}
        description={
          "Join GoatArch today! Register as an architect, interior designer, or manufacturer to showcase your work, connect with professionals, and reach a global audience."
        }
        title={"Register"}
      />
      <div className="full-width h-screen flex overflow-hidden">
        <div className="hidden flex-[1.2] lg:block">
          <img
            className="h-full w-full object-cover"
            src={getCurrentBackgroundImage()}
            alt={`Step ${currentStep} background`}
          />
        </div>
        <div className="flex-1 bg-theme-light-bg overflow-y-auto">
          <div className="min-h-full flex items-center justify-center py-[50px]">
            <div className="max-w-[450px] w-full">
              <h2 className="font-bold font-chivo text-[25px] leading-[30px] md:text-heading-3 mb-[50px] text-center">
                {currentStep === -1
                  ? "Let's create by registering your company"
                  : steps[currentStep]?.title}
              </h2>
              <form
                className="bg-white w-full p-8 shadow-3 rounded-[6px] md:p-12"
                onSubmit={handleSubmit(onSubmit)}
              >
                {renderStep()}
                {renderButtons()}
              </form>
              <Link
                className="flex mt-10 items-center z-10 relative transition-all duration-200 group p-3 w-full rounded-md bg-white text-gray-500 hover:text-gray-900 hover:-translate-y-[2px] flex-row-reverse mb-[30px]"
                to={`login/`}
              >
                <span className="block text-inherit w-full h-full rounded-md text-base font-semibold">
                  Already have an account? Login
                </span>
                <LogInIcon className="mr-5 text-black" />
              </Link>
              <div className="mt-5 flex flex-col items-center justify-center gap-2">
                <Link
                  to="/"
                  className="flex items-center justify-center gap-2 font-bold"
                >
                  <HomeIcon className="w-4" /> Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={() => {
            if (submissionState !== "submitting") {
              setIsPopupOpen(false);
            }
          }}
          showCloseIcon={submissionState !== "submitting"}
        >
          {renderPopupContent()}
        </Popup>
        <OtpVerificationPopup
          isOpen={isOtpPopupOpen}
          onClose={() => setIsOtpPopupOpen(false)}
          onVerify={handleVerifyOtp}
          email={watchEmail}
        />
        {showToast && (
          <Toast message={errorMessage} onClose={() => setShowToast(false)} />
        )}
      </div>
    </div>
  );
};