import React, { useState } from "react";
import { Input } from "../../../../components/shared";

const primaryTypes = [
  "Select Type",
  "Architects",
  "Interior Designer",
  "Manufacturer",
  "Landscape Designer",
  "Brand",
  "Freelancer",
  "Acoustic Consultant",
  "Engineering and Structural Consultant",
  "Facade / Enclosure Consultant",
  "Sustainability Consultant",
  "Artist and Art Designer",
  "MEP Consultant",
  "Urban Design and Planners",
  "Brand Designer",
  "General Contractor",
  "Fitout Contractor",
  "Design and Build Contractor",
  "Real Estate Developers",
  "Educational Institutions",
  "Government Agencies",
  "Law Firms",
  "Furniture supplier",
  "Lighting Supplier",
  "Acoustic supplier",
  "Sanitaryware Supplier",
  "Surfaces Supplier",
  "Flooring Supplier",
  "Decor supplier",
  "Partition Supplier",
  "Ceiling supplier",
  "Wall Covering Supplier",
  "Kitchen Supplier",
  "AV/IT Supplier",
  "Joinery",
  "Building Material Supplier",
  "Signage",
  "Technical Services and Installers",
  "Ceilings and Partition Installers",
  "Fabric and Upholstery",
  "Glass and Aluminium",
  "Electrical Supplier",
  "Engineered Materials",
  "Decor Finishes Installer",
  "3D Modelling",
  "Printing and Graphics",
  "Councils and Associations",
  "Events and Planning",
  "Others",
];

export const CompanyStepOne = ({ control }) => {
  const [primarySelected, setPrimarySelected] = useState("");

  const handlePrimaryChange = (event) => {
    const selectedValue = event.target.value;
    setPrimarySelected(selectedValue);
  };

  const filteredSecondaryTypes = primaryTypes.filter((type) => {
    if (type === "Select Type") return true;
    if (type === primarySelected) return false;
    if (
      (primarySelected === "Architects" ||
        primarySelected === "Interior Designer" ||
        primarySelected === "Landscape Designer" ||
        primarySelected === "Artist and Art Designer" ||
        primarySelected === "Urban Design and Planners") &&
      type === "Manufacturer"
    )
      return false;
    if (
      primarySelected === "Manufacturer" &&
      (type === "Architects" ||
        type === "Interior Designer" ||
        type === "Landscape Designer" ||
        type === "Artist and Art Designer" ||
        type === "Urban Design and Planners")
    )
      return false;
    return true;
  });

  return (
    <div className="space-y-6">
      <Input
        name="primaryType"
        control={control}
        type="select"
        label="Primary Type"
        options={primaryTypes.map((type) => ({ value: type, label: type }))}
        onChange={handlePrimaryChange}
        rules={{ required: "Primary type is required" }}
        allowSearch
      />
      <p className="text-black text-left text-sm">
        <span className="font-bold ">Note:</span> If you are a trusted brand,
        please select <span className="font-bold text-black">'Brand'</span> as
        your primary category.
      </p>
      <Input
        name="secondaryType"
        control={control}
        type="select"
        label="Secondary Type"
        options={filteredSecondaryTypes.map((type) => ({
          value: type,
          label: type,
        }))}
        rules={{
          required:
            "Please select Secondary type it was also required for maintaning your profile",
        }}
        allowSearch
      />
    </div>
  );
};
