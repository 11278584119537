import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MdShoppingBag,
  MdOutlineTask,
  MdBusiness,
  MdEvent,
  MdOutlineDataset,
  MdDashboard
} from "react-icons/md";
import { SiteLogo } from "../../shared";

const DashboardSidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const [isAdminExpanded, setIsAdminExpanded] = useState(false);

  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);

  const primaryType = parsedData?.primary_company_type?.toLowerCase();
  const secondaryType = parsedData?.secondary_company_type?.toLowerCase();

  const isAdmin = primaryType === "admin" || secondaryType === "admin";

  const showProjectRoute =
    primaryType === "architects" ||
    primaryType === "interior designer" ||
    primaryType === "landscape designer" ||
    primaryType === "artist and art designer" ||
    primaryType === "urban design and planners" ||
    secondaryType === "architects" ||
    secondaryType === "interior designer" ||
    secondaryType === "landscape designer" ||
    secondaryType === "artist and art designer" ||
    secondaryType === "urban design and planners";

  const showProductRoute =
    primaryType === "manufacturer" || secondaryType === "manufacturer";

  const sidebarItems = [
    { name: "Dashboard", icon: MdDashboard, path: "/dashboard" },
    showProductRoute && {
      name: "Add Products",
      icon: MdShoppingBag,
      path: "/dashboard/create-products/",
    },
    showProjectRoute && {
      name: "Add Projects",
      icon: MdOutlineTask,
      path: "/dashboard/create-projects/",
    },
    {
      name: "Update Company Details",
      icon: MdBusiness,
      path: "/dashboard/update-company-details/",
    },
    {
      name: "Add Events",
      icon: MdEvent,
      path: "/dashboard/add-event/",
    },
  ].filter(Boolean);

  const adminSidebarItems = [
    {
      name: "Products Under Review",
      icon: MdShoppingBag,
      path: "/dashboard/products-under-review/",
    },
    {
      name: "Architecture Tool",
      icon: MdOutlineDataset,
      path: "/dashboard/architecture-tool/",
    },
    {
      name: "Projects Under Review",
      icon: MdOutlineTask,
      path: "/dashboard/projects-under-review/",
    },
    {
      name: "Companies Profile Under Review",
      icon: MdBusiness,
      path: "/dashboard/companies-under-review/",
    },
  ];

  const NavItem = ({ item, isAdmin = false }) => {
    const isActive = location.pathname === item.path;
    return (
      <Link
        to={item.path}
        className={`flex items-center p-3 space-x-3 rounded-lg transition-all duration-200 ${
          isActive
            ? "bg-theme-color text-white shadow-md"
            : "text-gray-600 hover:bg-gray-100"
        }`}
      >
        <item.icon
          className={`w-6 h-6 ${isActive ? "text-white" : "text-gray-400"}`}
        />
        <span className={`${isActive ? "font-semibold" : "font-medium"}`}>
          {item.name}
        </span>
      </Link>
    );
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 z-50 bg-white shadow-xl transition-transform duration-300 lg:static lg:translate-x-0 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col h-full w-full">
        <div className="flex items-center justify-center h-20 border-b border-gray-200">
          <SiteLogo white={false} className="w-40" />
        </div>

        <nav className="flex-1 px-4 py-6 space-y-2 overflow-y-auto">
          {sidebarItems.map((item, index) => (
            <NavItem key={index} item={item} />
          ))}

          {isAdmin && (
            <>
              {/* <span className="font-semibold">Admin</span> */}
              {adminSidebarItems.map((item, index) => (
                <NavItem key={index} item={item} isAdmin />
              ))}
            </>
          )}
        </nav>

        {/* Uncomment if needed for logout */}
        {/* <div className="p-4 border-t border-gray-200">
          <button className="flex items-center justify-center w-full p-3 space-x-3 rounded-lg bg-red-500 text-white hover:bg-red-600 transition-colors duration-200">
            <MdLogout className="w-6 h-6" />
            <span className="font-medium">Logout</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardSidebar;
