import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SiteLogo } from "../../../shared/index";
import navdata from "../Navigation/nav.json";
import { Search, X, Menu } from "lucide-react";
import SearchOverlay from "../Search/searchOverlay";
import UserProfileMenu from "../Login User View";
import { GetStartedDropdown } from "../Get Started";

export const DefaultHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation();
  const authToken = localStorage.getItem("authToken");
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);

  useEffect(() => {
    let lastScroll = 0;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      setIsVisible(currentScroll <= 0 || currentScroll < lastScroll);
      setIsScrolled(currentScroll > 0);
      lastScroll = currentScroll;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      if (window.innerWidth <= 1024) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const getLinkClass = (link) => {
    const isActive = location.pathname === link;
    const baseClass = "block font-medium text-base";
    const colorClass = isActive
      ? "text-theme-color font-bold"
      : isScrolled && !isMenuOpen
      ? "text-black"
      : isMenuOpen
      ? "text-black"
      : "text-white";
    return `${baseClass} ${colorClass} hover:text-theme-color`;
  };

  return (
    <header
      className={`z-[9999] flex py-4 px-6 lg:px-10 sm:px-10 font-sans min-h-[70px] tracking-wide fixed w-full transition-all duration-300 ${
        isVisible ? "top-0" : "-top-full"
      } ${isScrolled ? "bg-white shadow-md" : "bg-transparent"}`}
      style={{ color: isScrolled ? "black" : "white" }}
    >
      <div className="flex flex-wrap items-center gap-4 w-full">
        <SiteLogo className="w-36" white={!isScrolled} />

        <div
          className={`lg:flex lg:flex-auto lg:ml-12 ${
            isMenuOpen ? "block" : "hidden"
          } max-lg:fixed max-lg:inset-0 max-lg:bg-white max-lg:z-50 max-lg:overflow-y-auto`}
        >
          <div className="lg:hidden flex justify-between items-center p-4 border-b border-gray-200">
            <div className="flex items-center space-x-3">
              {authToken && (
                <>
                  <img
                    src={parsedData?.company_logo_image}
                    alt={parsedData?.company_name}
                    className="w-10 h-10 rounded-full object-contain"
                  />
                  <span className="font-semibold text-black">
                    {parsedData?.company_name}
                  </span>
                </>
              )}
            </div>
            <button
              className="rounded-full bg-gray-200 p-2"
              onClick={toggleMenu}
            >
              <X className="w-6 h-6 text-gray-600" />
            </button>
          </div>

          <div className="lg:flex lg:flex-auto max-lg:p-6 max-lg:h-full">
            <ul className="lg:flex lg:gap-x-8 max-lg:space-y-2">
              {navdata.primary.map((item, index) => (
                <li key={index} className="max-lg:border-b max-lg:py-3">
                  <Link
                    to={item.link}
                    className={getLinkClass(item.link)}
                    style={{ fontFamily: "Lato, sans-serif" }}
                    onClick={toggleMenu}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="lg:flex lg:items-center ml-auto max-lg:block lg:space-x-8">
              {navdata.secondary.map((item, index) => (
                <li
                  key={index}
                  className="max-lg:border-b max-lg:py-3 max-lg:mt-2 lg:hidden"
                >
                  <Link
                    to={item.link}
                    className={`hover:text-theme-color block text-base ${
                      isScrolled && !isMenuOpen
                        ? "text-gray-600"
                        : isMenuOpen
                        ? "text-black"
                        : "text-white"
                    }`}
                    onClick={toggleMenu}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>

            {!authToken && (
              <div className="lg:hidden mt-6 space-y-4">
                <Link
                  to="/login"
                  className="hover:text-theme-color block font-bold text-[15px] text-black"
                  onClick={toggleMenu}
                >
                  Log in
                </Link>
                <Link
                  to="/register"
                  className="w-full px-4 py-2.5 text-sm rounded font-bold border-2 bg-black text-white border-black hover:bg-theme-color hover:border-theme-color block text-center"
                  onClick={toggleMenu}
                >
                  Register
                </Link>
              </div>
            )}
          </div>

          {authToken && (
            <div className="lg:hidden mt-auto p-6 border-t border-gray-200">
              <Link
                to="/"
                className="block px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-200 flex items-center"
                onClick={() => {
                  localStorage.clear();
                  toggleMenu();
                }}
              >
                <svg
                  className="w-5 h-5 mr-3 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Logout
              </Link>
            </div>
          )}
        </div>

        <div className="flex items-center ml-auto space-x-6">
          <button
            onClick={toggleSearch}
            className="hover:text-theme-color hidden lg:block"
          >
            <Search className="h-5 w-5" />
          </button>
          {authToken ? (
            <div className="max-lg:hidden">
              <UserProfileMenu
                userAvatar={parsedData?.company_logo_image}
                isScrolled={isScrolled}
              />
            </div>
          ) : (
            <>
              <div className="border-l border-[#333] h-6 max-lg:hidden"></div>
              <Link
                to="/login"
                className={`hover:text-theme-color block font-bold text-[15px] max-lg:hidden ${
                  isScrolled ? "text-gray-600" : "text-white"
                }`}
              >
                Log in
              </Link>
              <GetStartedDropdown
                isScrolled={isScrolled}
                className="max-lg:hidden"
              />
            </>
          )}

          <button
            onClick={toggleSearch}
            className="hover:text-theme-color lg:hidden"
          >
            <Search className="h-5 w-5" />
          </button>

          <button className="lg:hidden" onClick={toggleMenu}>
            <Menu className="w-7 h-7" />
          </button>
        </div>
      </div>
      <SearchOverlay isOpen={isSearchOpen} onClose={toggleSearch} />
    </header>
  );
};
