export const filters = [
  {
    name: "Color",
    options: [
      { name: "Red", code: "#FF0000" },
      { name: "Blue", code: "#0000FF" },
      { name: "Green", code: "#00FF00" },
      { name: "Yellow", code: "#FFFF00" },
      { name: "Black", code: "#000000" },
      { name: "White", code: "#FFFFFF" },
    ],
  },
  {
    name: "Location",
    options: [{ name: "Interior" }, { name: "Exterior" }, { name: "Both" }],
  },
  {
    name: "Source",
    options: [
      { name: "Manufactured" },
      { name: "Natural" },
      { name: "Recycled" },
      { name: "Custom" },
    ],
  },
  {
    name: "Item Type",
    options: [
      { name: "Wall" },
      { name: "Door" },
      { name: "Ceiling" },
      { name: "Floor" },
      { name: "Window" },
      { name: "Furniture" },
    ],
  },
];

export const sampleProducts = [
  {
    id: 1,
    uniqueCode: "WP-001",
    name: "Modern Wall Panel",
    image: "iyhG1KFuphE",
    itemType: "Wall",
    color: "White",
    location: "Interior",
    source: "Manufactured",
    application:
      "Ideal for contemporary living spaces, offices, and commercial interiors. Can be installed vertically or horizontally for different visual effects.",
    description:
      "Premium quality wall panel featuring a sleek, minimalist design. Made from high-grade materials with excellent durability and easy maintenance properties.",
  },
  {
    id: 2,
    uniqueCode: "DR-002",
    name: "Wooden Door",
    image: "iyhG1KFuphE",
    itemType: "Door",
    color: "Red",
    location: "Both",
    source: "Natural",
    application:
      "Suitable for both interior and exterior applications, perfect for residential entrances and room divisions.",
    description:
      "Handcrafted wooden door made from sustainable hardwood. Features excellent insulation properties and classic design elements.",
  },
];
