import React from "react";
import { Link } from "react-router-dom";
import originalLogo from "../../../assets/images/Logo/GA-Logo-black.png";
import originalWhite from "../../../assets/images/Logo/GA-Logo-white.png";

export const SiteLogo = ({ white, width, height, className }) => {
  const logoSrc = white ? originalWhite : originalLogo;
  return (
    <Link to={"/goatarch-home/"}>
      <img
        src={logoSrc}
        alt="Logo"
        width={width}
        height={height}
        className={className}
        style={{ cursor: "pointer" }}
      />
    </Link>
  );
};
