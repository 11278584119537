import { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from "../../components/layout/layout";
import BCMImage from "../../assets/images/Brands/brand-v-2.jpg";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { SelectedItemsTable } from "./components/table";
import { ProductGrid } from "./components/material-library";
import { FilterSection } from "./components/filter";
import { sampleProducts } from "./data";
import { BreadcrumbComponentTwo } from "../../components/shared";

export const ArchToolFiltering = () => {
  const [specToolData, setSpecToolData] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filteredProducts, setFilteredProducts] = useState(sampleProducts);
  const [addedProducts, setAddedProducts] = useState(new Set());
  const [selectedItems, setSelectedItems] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchSpecData = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/get_spectool`);
        setSpecToolData(response.data.spec_tool);
      } catch (error) {
        console.error("Error fetching fetchSpecData:", error);
      }
    };

    fetchSpecData();
  }, []);

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prev) => {
      const newFilters = {
        ...prev,
        [filterType]: {
          ...prev[filterType],
          [value]: !prev[filterType]?.[value],
        },
      };

      const filtered = sampleProducts.filter((product) => {
        return Object.entries(newFilters).every(([filterType, values]) => {
          const activeFilters = Object.entries(values)
            .filter(([, isSelected]) => isSelected)
            .map(([value]) => value);

          if (activeFilters.length === 0) return true;

          switch (filterType) {
            case "Color":
              return activeFilters.includes(product.color);
            case "Location":
              return activeFilters.includes(product.location);
            case "Source":
              return activeFilters.includes(product.source);
            case "Item Type":
              return activeFilters.includes(product.itemType);
            default:
              return true;
          }
        });
      });

      setFilteredProducts(filtered);
      return newFilters;
    });
  };

  const handleAddProduct = (productId) => {
    setAddedProducts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(productId)) {
        newSet.delete(productId);
        setSelectedItems((prev) =>
          prev.filter((item) => item.id !== productId)
        );
      } else {
        newSet.add(productId);
        const productToAdd = sampleProducts.find((p) => p.id === productId);
        setSelectedItems((prev) => [...prev, productToAdd]);
      }
      return newSet;
    });
  };

  const handleRemoveFromTable = (productId) => {
    setAddedProducts((prev) => {
      const newSet = new Set(prev);
      newSet.delete(productId);
      return newSet;
    });
    setSelectedItems((prev) => prev.filter((item) => item.id !== productId));
  };

  return (
    <Layout>
      <BreadcrumbComponentTwo bgImage={BCMImage} title="Arch Tool Filtering" />
      <div className="bg-white">
        <div>
          <main className="mx-auto px-4 sm:px-6 lg:px-24">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                Arch Tools
              </h1>
              <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" />
                </button>
              </div>
            </div>

            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <FilterSection
                  selectedFilters={selectedFilters}
                  onFilterChange={handleFilterChange}
                  isMobileOpen={mobileFiltersOpen}
                  onMobileToggle={setMobileFiltersOpen}
                />

                {/* Product grid */}
                <div className="lg:col-span-3">
                  <ProductGrid
                    products={filteredProducts}
                    addedProducts={addedProducts}
                    onAddProduct={handleAddProduct}
                  />
                </div>
              </div>
            </section>
          </main>
        </div>

        {/* Selected Items Table */}
        <SelectedItemsTable
          items={selectedItems}
          onRemoveItem={handleRemoveFromTable}
        />
      </div>
    </Layout>
  );
};
