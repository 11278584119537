import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  canonicalUrl,
  structuredData,
  noindex = false,
  keywords = [],
  image,
}) => {
  const defaultTitle = "GoatArch Design and Products";
  const defaultDescription =
    "Connecting architects, designers, and brands with innovative solutions and inspiring projects.";
  const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const siteUrl = "https://goatarch.com";
  const defaultLogo = `${siteUrl}/static/media/GA-Logo-black.png`;

  // Normalize and process image
  const processImage = (imageInput) => {
    // If no image provided, use default logo
    if (!imageInput) return defaultLogo;

    // If it's a string URL
    if (typeof imageInput === "string") {
      // Ensure full URL
      return imageInput.startsWith("http")
        ? imageInput
        : `${siteUrl}${
            imageInput.startsWith("/") ? imageInput : `/${imageInput}`
          }`;
    }

    // Fallback to default logo if image processing fails
    return defaultLogo;
  };

  // Process image and description
  const processedImage = processImage(image || defaultLogo);
  const processedDescription = (description || defaultDescription)
    .replace(/<[^>]*>/g, "") // Remove HTML tags
    .substring(0, 160); // Limit to 160 characters

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={processedDescription} />
      <link rel="canonical" href={canonicalUrl || siteUrl} />

      {/* Keywords */}
      {keywords?.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}

      {/* Indexing */}
      <meta
        name="robots"
        content={noindex ? "noindex, follow" : "index, follow"}
      />

      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={processedDescription} />
      <meta property="og:url" content={canonicalUrl || siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={processedImage} />
      <meta property="og:image:secure_url" content={processedImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={title || defaultTitle} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@goatarch" />
      <meta name="twitter:creator" content="@goatarch" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={processedDescription} />
      <meta name="twitter:image" content={processedImage} />
      <meta name="twitter:image:alt" content={title || defaultTitle} />

      {/* Structured Data */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: fullTitle,
            description: processedDescription,
            url: canonicalUrl || siteUrl,
            logo: defaultLogo,
            image: processedImage,
            ...structuredData,
          })}
        </script>
      )}

      {/* Verification and Additional SEO Tags */}
      <meta name="author" content="GoatArch" />
      <meta name="copyright" content="GoatArch Design and Products" />
      <meta name="revisit-after" content="7 days" />
    </Helmet>
  );
};

export default SEO;
