import { Home } from "./Pages/Home";
import { AboutCompany } from "./Pages/About";
import Pricing from "./Pages/Pricing";

// Projects
import { Projects } from "./Pages/Projects";
import { ProjectDetails } from "./Pages/Projects/Project Details";
import { DashboardProjects } from "./Pages/Dashboard/Projects";
import { CreateProject } from "./Pages/Dashboard/Add Project";

// Company
import CompanyBrowsing from "./Pages/Company Browsing";
import { CompanyProfile } from "./Pages/Company Profile";
import { UpdateCompanyDetails } from "./Pages/Dashboard/Update Company Details";
import { CompanyProfileSetup } from "./Pages/Company Profile Setup";

// User
import { UserProfile } from "./Pages/User Profile";
import { DashboardProfile } from "./Pages/Dashboard/Profile";
import { UpdateIndividualProfile } from "./Pages/Dashboard/Individual Profile Settings";

// Products
import { Products } from "./Pages/Products";
import ProductDetails from "./Pages/Products/Product Details";
import { CreateProduct } from "./Pages/Dashboard/Add Product";

// Authentication
import { Login } from "./Pages/Auth/Login";
import { ResetPassword } from "./Pages/Auth/Reset Password";
import { ForgotPassword } from "./Pages/Auth/Forgot Password";

// Other Pages
import { Error404Page } from "./Pages/404";
import { PrivacyPolicy } from "./Pages/Policy Pages/Privacy Policy";

// Dashboard Admin
import { CompaniesProfileUnderReview } from "./Pages/Dashboard/Admin/Under Review/Company Profile";
import { ProductsUnderReview } from "./Pages/Dashboard/Admin/Under Review/Products";
import { ProjectsUnderReview } from "./Pages/Dashboard/Admin/Under Review/Projects";
import { GlobalSearchResultPage } from "./Pages/Search Results";
import { ContactPage } from "./Pages/Contact";
import { TermsAndConditions } from "./Pages/Policy Pages/Terms and Conditions";
import { TermsOfUse } from "./Pages/Policy Pages/Terms of Uses";
import { ReturnAndRefundPolicy } from "./Pages/Policy Pages/Return and Refund";
import { AdminAnalytics } from "./Pages/Dashboard/Admin/Dashboard";
import { AllCompaniesTable } from "./Pages/Dashboard/Admin/Companies";
import { EventsPage } from "./Pages/Events";
import EventDetailPage from "./Pages/Events/Event Details";
import AddEventsDetails from "./Pages/Dashboard/Add Event";
import { ArticleOne } from "./Pages/Articles/Revolutionizing Professional Networking";
import { ArticleTwo } from "./Pages/Articles/Maximizing Your GoatArch Presence";
import DesignerBenefitsArticle from "./Pages/Articles/How is goatarch.com beneficial for designers/inde";
import SustainableMaterialsArticle from "./Pages/Articles/Quality Materials";
import MaterialLibrarySteps from "./Pages/Products/Material Library";
import { ArchitectureTool } from "./Pages/Dashboard/Architecture Tool";
import { GoatArchForum } from "./Pages/Forum";
import AcousticComfortBlog from "./Pages/Articles/How Acoustic Comfort Enhances your building design";
import { ArticlePage } from "./Pages/Articles";
import { Brands } from "./Pages/Brands";
import DesignEvolutionBlog from "./Pages/Articles/How does a great design evolves when you a strong design concept";
import { ArchToolFiltering } from "./Pages/Arch Tool";
import { GoatArchGetStarted } from "./Get Started";

export const routes = [
  {
    path: "/",
    component: GoatArchGetStarted,
  },
  {
    path: "/goatarch-home/",
    component: Home,
    expect: true,
  },

  // Projects Routes
  {
    path: "/projects/",
    component: Projects,
    exact: true,
  },
  {
    path: "/projects/project-details/:id/",
    component: ProjectDetails,
    exact: true,
  },
  {
    path: "/dashboard/create-projects/",
    component: CreateProject,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/projects/",
    component: DashboardProjects,
    exact: true,
    private: true,
  },

  // Products Routes
  {
    path: "/products/products-details/:id/",
    component: ProductDetails,
    exact: true,
  },
  {
    path: "/material-library/",
    component: Products,
    exact: true,
  },
  {
    path: "/dashboard/create-products/",
    component: CreateProduct,
    exact: true,
    private: true,
  },

  // Authentications Routes
  {
    path: "/login/",
    component: Login,
    exact: true,
  },
  {
    path: "/register/",
    component: CompanyProfileSetup,
    exact: true,
  },
  {
    path: "/reset-password/:token/",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/forgot-password/",
    component: ForgotPassword,
    exact: true,
  },

  // Company Part Routes
  // {
  //   path: "/company-profile-setup/",
  //   component: CompanyProfileSetup,
  //   exact: true,
  // },
  {
    path: "/companies/",
    component: CompanyBrowsing,
    exact: true,
  },
  {
    path: "/company-profile/:id/",
    component: CompanyProfile,
    exact: true,
  },
  {
    path: "/dashboard/update-company-details/",
    component: UpdateCompanyDetails,
    exact: true,
    private: true,
  },

  // User Profile Routes
  {
    path: "/user-profile/",
    component: UserProfile,
    exact: true,
  },
  // {
  //   path: "/profile-setup/",
  //   component: IndividualProfileSetup,
  //   exact: true,
  // },
  {
    path: "/dashboard/profile-settings/",
    component: UpdateIndividualProfile,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/companies-under-review/",
    component: CompaniesProfileUnderReview,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/products-under-review/",
    component: ProductsUnderReview,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/projects-under-review/",
    component: ProjectsUnderReview,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/",
    component: UpdateCompanyDetails,
    exact: true,
    private: true,
  },

  // Other Routes
  {
    path: "/about-us/",
    component: AboutCompany,
    exact: true,
  },
  {
    path: "/events/",
    component: EventsPage,
    exact: true,
  },
  {
    path: "/events/event-details/",
    component: EventDetailPage,
    exact: true,
  },
  {
    path: "/privacy-policy/",
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: "/terms-and-conditions/",
    component: TermsAndConditions,
    exact: true,
  },
  {
    path: "/terms-of-uses/",
    component: TermsOfUse,
    exact: true,
  },
  {
    path: "/return-and-refund-policy/",
    component: ReturnAndRefundPolicy,
    exact: true,
  },
  {
    path: "/pricing/",
    component: Pricing,
    exact: true,
  },
  {
    path: "/contact-us/",
    component: ContactPage,
    exact: true,
  },
  {
    path: "*",
    component: Error404Page,
  },
  {
    path: "/search-results/",
    component: GlobalSearchResultPage,
    exact: true,
  },

  // Asmin Routes

  {
    path: "/admin/analytics/",
    component: AdminAnalytics,
    exact: true,
  },
  {
    path: "/admin/all-companies-table/",
    component: AllCompaniesTable,
    exact: true,
  },
  {
    path: "/dashboard/add-event/",
    component: AddEventsDetails,
    exact: true,
  },

  // Blogs
  {
    path: "/why-goatarch-revolutionizing-professional-networking-in-architecture-design/",
    component: ArticleOne,
    exact: true,
  },
  {
    path: "/maximizing-your-goatarch-presence-projects-products-showcase-guide/",
    component: ArticleTwo,
    exact: true,
  },
  {
    path: "/how-is-goatarch-com-beneficial-for-designers/",
    component: DesignerBenefitsArticle,
    exact: true,
  },
  {
    path: "/quality-materials-the-foundation-of-sustainable-architecture/",
    component: SustainableMaterialsArticle,
    exact: true,
  },
  {
    path: "/how-acoustic-comfort-enhances-your-building-design/",
    component: AcousticComfortBlog,
    exact: true,
  },
  {
    path: "/join-our-material-library/",
    component: MaterialLibrarySteps,
    exact: true,
  },
  {
    path: "/dashboard/architecture-tool/",
    component: ArchitectureTool,
    exact: true,
  },
  {
    path: "/articles/",
    component: ArticlePage,
    exact: true,
  },
  {
    path: "/from-concept-to-masterpiece-the-evolution-of-great-design/",
    component: DesignEvolutionBlog,
    exact: true,
  },

  // Forum
  {
    path: "/forum/",
    component: GoatArchForum,
    exact: true,
  },
  {
    path: "/brands/",
    component: Brands,
    exact: true,
  },
  {
    path: "/arch-tool-filtering/",
    component: ArchToolFiltering,
    exact: true,
  },
];
